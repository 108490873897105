import styled from 'styled-components';
import { LAYOUT } from '@/constants/styles';

export const StyledNoAvailability = styled.p`
  font-size: 12px;
  font-style: italic;
  line-height: normal;
  margin: 0 0 ${LAYOUT.GUTTER}px;
`;

interface StyledTimeSelectProps {
  $showLoading: boolean;
  $showError: boolean;
}

export const StyledTimeSelect = styled.div<StyledTimeSelectProps>`
  position: relative;
  transition: opacity 0.2s linear;
  ${({ $showLoading }) =>
    $showLoading &&
    `
opacity: 0.5;
`}
  .select-style {
    ${({ $showError }) =>
      $showError &&
      `
  border-color: red
`}
  }
`;

interface StyledLoaderProps {
  $right?: number;
  $top?: number;
}

export const StyledLoader = styled.div<StyledLoaderProps>`
  height: 22px;
  position: absolute;
  right: ${({ $right }) => $right ?? 35}px;
  top: ${({ $top }) => $top ?? 14}px;
  width: 22px;
  /* Spinner Circle Rotation */
  border: 3px rgba(0, 0, 0, 0.25) solid;
  border-top: 3px rgba(0, 0, 0, 1) solid;
  border-radius: 50%;
  -webkit-animation: spCircRot 0.6s infinite linear;
  animation: spCircRot 0.6s infinite linear;
  @-webkit-keyframes spCircRot {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
  @keyframes spCircRot {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const StyledDayTimeSelectContainer = styled.div<{ $stack: boolean }>`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  @media (max-width: 767px) {
    display: ${({ $stack = true }) => ($stack ? 'block' : 'flex')};
  }
  > div {
    flex: 1;
    &:first-child {
      margin-right: ${LAYOUT.GUTTER}px;
      @media (max-width: 767px) {
        margin-right: ${({ $stack = true }) => ($stack ? 0 : `${LAYOUT.GUTTER}px`)};
      }
    }
  }
`;
