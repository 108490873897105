import styled from 'styled-components';
import { getFontStyles } from '@/redux/cmsConfig/utils';

interface StyledSelectContainerProps {
  width?: string;
}

export const StyledSelectContainer = styled.div<StyledSelectContainerProps>(({ theme, width }) => ({
  position: 'relative',

  '.select-style': {
    background: 'url("/static/img/dropdown-expand-temp.png") no-repeat right 50%',
    backgroundColor: theme.forms.background_color ?? 'transparent',
    border: `1px solid ${theme.forms.border_color ?? 'transparent'}`,
    borderRadius: `${theme.forms.border_radius ?? 0}px`,
    overflow: 'hidden',
    padding: 0,
    minWidth: width ?? '100%',

    select: {
      '-moz-appearance': 'none',
      '-webkit-appearance': 'none',
      appearance: 'none',
      backgroundColor: 'transparent',
      backgroundImage: 'none',
      border: 'none',
      height: `${theme.forms.field_height}px`,
      padding: '0px 30px 0 12px',
      width: '100%',
      ...getFontStyles(theme.forms.font, ['color', 'font_family', 'font_size']),
    },
  },
}));
