import * as Dialog from '@radix-ui/react-dialog';
import { type ReactNode, useEffect, isValidElement } from 'react';
import { Content, ContentWrapper, Overlay, Container } from './container.styles';
import { ModalContext, useModalContext } from './context';
import { useAnimatedVisibility } from '@/utils/useAnimatedVisibility';

/*
 * The `ModalWrapper` handles opening and closing the modal, while also setting up
 * a local provider and the radix accessible modal component. By placing the provider
 * inside of the portal, we can ensure a reset of local state once the modal has been
 * dismissed or accepted.
 */

interface ModalContainerProps {
  requestVisibility?: boolean;
  dismissible?: boolean;
  trigger?: (open: () => void) => ReactNode;
  onCancel?: () => void;
  children: ((close: () => void) => ReactNode) | ReactNode;
}

export const ModalContainer = ({
  requestVisibility = false,
  dismissible = true,
  trigger,
  children,
  onCancel,
}: ModalContainerProps) => {
  const { isMounted, shouldDisplay, toggle, ref } = useAnimatedVisibility();

  useEffect(() => {
    requestVisibility && toggle();
  }, [requestVisibility]);

  return (
    <Dialog.Root open={isMounted}>
      {trigger && trigger(toggle)}

      <Dialog.Portal>
        {/* @ts-expect-error ref mismatch */}
        <Container ref={ref} $isVisible={shouldDisplay}>
          <Overlay data-testid="modal-overlay" />

          <ModalProvider
            onCancel={() => {
              onCancel && onCancel();
              toggle();
            }}
            onToggle={toggle}
            dismissible={dismissible}
          >
            <ModalContents $shouldDisplay={shouldDisplay}>{children}</ModalContents>
          </ModalProvider>
        </Container>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

/*
 * the `ModalProvider` component creates a local store to allow flexibility with setting
 * up modal while also ensuring we dont need to add cancel events in multiple places
 */

interface ModalProviderProps {
  children: ReactNode;
  onCancel: () => void;
  onToggle: () => void;
  dismissible: boolean;
}

const ModalProvider = ({ children, onCancel, onToggle, dismissible }: ModalProviderProps) => {
  return (
    <ModalContext.Provider value={{ onCancel, onToggle, dismissible }}>
      {children}
    </ModalContext.Provider>
  );
};

/*
 * the `Contents` component displays the modal contents with events for generic dismissal (escape, etc)
 */

interface ModalContentsProps {
  $shouldDisplay: boolean;
  children: ((close: () => void) => ReactNode) | ReactNode;
}

const ModalContents = ({
  $shouldDisplay,
  children,
  ...otherProps
}: ModalContentsProps & React.HTMLProps<HTMLDivElement>) => {
  const { onCancel, onToggle, dismissible } = useModalContext();

  return (
    <>
      <ContentWrapper $isVisible={$shouldDisplay} {...otherProps}>
        <Content
          onEscapeKeyDown={() => {
            dismissible && onCancel();
          }}
          onPointerDownOutside={() => {
            dismissible && onCancel();
          }}
        >
          {isValidElement(children) && children}
          {typeof children === 'function' && children(onToggle)}
        </Content>
      </ContentWrapper>
    </>
  );
};
