import { type AllergenItem } from '@koala/sdk';
import merge from 'lodash/merge';
import { Component } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import {
  StyledAllergenButton,
  StyledAllergenContainer,
  StyledAllergens,
  StyledAllergensIntro,
  StyledButton,
  StyledCopy,
  StyledFooter,
  StyledGenericModal,
  StyledGenericModalContent,
  StyledLink,
} from './styles';
import { pageViewEventHandler } from '@/analytics/events';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { ConnectedXButton } from '@/components/uielements/X';
import { GenericFocusTrap } from '@/components/uielements/genericFocusTrap';
import { K_ANALYTICS_EVENTS, MODAL } from '@/constants/events';
import allergenActions from '@/redux/allergens/actions';
import { type RootState } from '@/types/app';
import { safelyGetValueForKA } from '@/utils/events';
import { fireGaEvent, gaActions, gaCats } from '@/utils/googleAnalytics';
import { fireKAnalyticsEvent } from '@/utils/koalaAnalytics';

interface Props extends ReduxProps {
  displayOnMobile: boolean;
  maxWidth?: number;
  $fromTop: number;
}

interface State {
  showModal: boolean;
  activeAllergens: number[];
}

class AllergensModal extends Component<Props, State> {
  state = {
    showModal: false,
    activeAllergens: [],
  };

  componentDidMount() {
    if (this.props.allergens.allergens.length === 0) {
      this.props.fetchAllergens();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!this.state.showModal && this.state.showModal !== prevState.showModal) {
      this.setState({
        activeAllergens: this.props.allergens.activeAllergens.slice(0),
      });
    }

    if (this.props.allergens.activeAllergens !== prevProps.allergens.activeAllergens) {
      this.setState({
        activeAllergens: this.props.allergens.activeAllergens.slice(0),
      });
    }
  }

  toggleModal(showModal: boolean) {
    this.setState({ showModal });
  }

  toggleAllergen(allergenId: number) {
    const activeAllergens: number[] = merge([], this.state.activeAllergens);
    const index = activeAllergens.indexOf(allergenId);

    if (index > -1) {
      activeAllergens.splice(index, 1);
    } else {
      activeAllergens.push(allergenId);
    }

    this.setState({
      activeAllergens,
    });
  }

  applyPreferences() {
    this.props.updateAllergens(this.state.activeAllergens);
    this.toggleModal(false);

    const selectedAllergenNames: string[] = [];
    // Map through our active allergen ID array and get the names
    this.state.activeAllergens.map((allergenId: number) => {
      const allergenName = safelyGetValueForKA(
        this.props.allergens.allergens,
        'id',
        allergenId,
        'label',
      );
      allergenName && selectedAllergenNames.push(allergenName);
    });
    // Join array into a string
    const selectedAllergensString = selectedAllergenNames.join(', ');

    // GA Event
    fireGaEvent(gaCats.browse, gaActions.dietaryPreferencesUpdated, {
      label: selectedAllergensString,
    });

    // KA Event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.DIETARY_PREFERENCES_UPDATED, {
      name: selectedAllergensString,
    });
  }

  render() {
    const { showModal, activeAllergens } = this.state;
    const { allergens, displayOnMobile, maxWidth } = this.props;

    if (!allergens.allergens.length) {
      return null;
    }

    return (
      <>
        <StyledAllergenContainer displayOnMobile={displayOnMobile}>
          <StyledLink
            $isActive={!!this.props.allergens.activeAllergens.length}
            onClick={() => {
              this.toggleModal(true);
              // Consolidated pageview events
              pageViewEventHandler(MODAL.DIETARY_PREFERENCES_MODAL);
            }}
          >
            <svg width="19" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.535 1.574c.578 0 1.074.446 1.074 1.023S6.113 3.62 5.535 3.62c-.578 0-1.074-.446-1.074-1.023s.496-1.023 1.074-1.023ZM5.507.262c-1.101 0-2.037.709-2.34 1.68H.66a.643.643 0 0 0-.66.654c0 .367.303.656.688.656h2.506c.276.97 1.24 1.678 2.34 1.678 1.13 0 2.066-.708 2.369-1.678h10.16c.386 0 .69-.289.69-.656 0-.367-.304-.656-.69-.656H7.904C7.573.971 6.636.262 5.507.262Zm8.316 6.558c.579 0 1.074.446 1.074 1.023s-.495 1.023-1.074 1.023c-.578 0-1.074-.446-1.074-1.023s.468-1.023 1.074-1.023Zm-.028-1.312c-1.101 0-2.037.708-2.34 1.679H.688c-.413 0-.688.288-.688.656 0 .367.303.655.688.655h10.767c.275.97 1.239 1.68 2.34 1.68 1.13 0 2.066-.71 2.369-1.68h1.9c.385 0 .688-.288.688-.655 0-.368-.303-.656-.688-.656h-1.9c-.303-.97-1.24-1.679-2.369-1.679Zm-3.882 8.105c0-.577-.496-1.023-1.073-1.023a1.07 1.07 0 0 0-1.074 1.023c0 .577.496 1.023 1.074 1.023.577 0 1.073-.446 1.073-1.023Zm-3.443-.656c.304-.97 1.24-1.679 2.342-1.679 1.128 0 2.065.709 2.368 1.679h6.884c.385 0 .688.289.688.656 0 .367-.303.656-.688.656h-6.857c-.303.97-1.238 1.678-2.368 1.678-1.101 0-2.065-.708-2.341-1.678H.688c-.385 0-.688-.289-.688-.656 0-.367.275-.656.688-.656H6.47Z"
                fill="currentColor"
              />
            </svg>

            <span>
              <StringAccessor accessor="dietary_preferences.menu_trigger" />
            </span>
          </StyledLink>
        </StyledAllergenContainer>

        {showModal && (
          <GenericFocusTrap>
            <StyledGenericModal>
              <StyledGenericModalContent maxWidth={maxWidth}>
                <ConnectedXButton
                  onClick={() => this.toggleModal(false)}
                  name="Dietary preference modal"
                />
                <StyledCopy>
                  <StringAccessor
                    accessor="dietary_preferences.modal_eyebrow"
                    tag="h3"
                    html={true}
                  />
                  <StringAccessor
                    accessor="dietary_preferences.modal_headline"
                    tag="h1"
                    html={true}
                  />
                  <StringAccessor
                    accessor="dietary_preferences.modal_subheadline"
                    tag="p"
                    html={true}
                  />
                </StyledCopy>

                {/*  */}
                <StyledAllergensIntro>Select all that apply</StyledAllergensIntro>

                {/*  */}
                <StyledAllergens>
                  {allergens.allergens.map((allergen: AllergenItem) => (
                    <StyledAllergenButton
                      key={allergen.id}
                      onClick={() => this.toggleAllergen(allergen.id)}
                      // @ts-expect-error
                      $isActive={activeAllergens.includes(allergen.id)}
                    >
                      <span>{allergen.label}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="14"
                        viewBox="0 0 19 14"
                      >
                        <path
                          fill="currentColor"
                          fillRule="nonzero"
                          d="M.39 7.59C-.792 6.505.98 4.73 2.163 5.913l4.629 4.537L16.837.39c1.182-1.183 2.955.592 1.773 1.775L6.792 14 .39 7.59z"
                        />
                      </svg>
                    </StyledAllergenButton>
                  ))}
                </StyledAllergens>

                {/*  */}
                <StyledFooter>
                  <StringAccessor
                    accessor="dietary_preferences.modal_disclaimer"
                    tag="p"
                    html={true}
                  />
                  <StyledButton onClick={() => this.applyPreferences()} size="large">
                    <StringAccessor accessor="dietary_preferences.modal_cta" />
                  </StyledButton>
                </StyledFooter>
              </StyledGenericModalContent>
            </StyledGenericModal>
          </GenericFocusTrap>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  allergens: state.app.allergens,
});

const mapDispatchToProps = {
  fetchAllergens: allergenActions.fetchAllergens,
  updateAllergens: allergenActions.updateAllergens,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(AllergensModal);
