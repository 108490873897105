import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import {
  StickWithButton,
  StyledConfirm,
  StyledContinueButton,
} from '@/components/fulfillmentManager/styles';
import { API_CONVEYANCE_TYPES } from '@/constants/events';
import conveyanceModeActions from '@/redux/conveyanceMode/actions';
import { selectConveyance } from '@/redux/conveyanceMode/reducer';
import globalActions from '@/redux/global/actions';
import { type RootState } from '@/types/app';

export const PickupConfirm = () => {
  const dispatch = useDispatch();
  const locationId = useSelector(
    // TODO: Why we are not using app.locations?
    (state: RootState) => state.app.global.fulfillmentModal?.location?.id,
  );

  const { time_wanted } = useSelector(selectConveyance);

  return (
    <StyledConfirm>
      <StringAccessor tag="h2" accessor="delivery.switch_to_pickup_header" html={true} />
      <div>
        <StringAccessor accessor="delivery.switch_to_pickup_subheader" html={true} />
      </div>
      <StyledContinueButton
        type="button"
        onClick={() => {
          dispatch(
            conveyanceModeActions.clearDeliveryAddress(
              /** @TODO ensure that `locationId` isn't null. */
              // @ts-expect-error
              locationId,
            ),
          );
          dispatch(
            conveyanceModeActions.conveyanceModeSet(
              {
                type: API_CONVEYANCE_TYPES.PICKUP,
                details: [],
              },
              time_wanted,
            ),
          );
          dispatch(globalActions.toggleFulfillmentModal(false));
        }}
      >
        <StringAccessor accessor="delivery.switch_to_pickup_cta" />
      </StyledContinueButton>
      <div>
        <StickWithButton onClick={() => dispatch(globalActions.toggleFulfillmentModal(false))}>
          <StringAccessor accessor="delivery.stick_with_delivery_cta" />
        </StickWithButton>
      </div>
    </StyledConfirm>
  );
};
