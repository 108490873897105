import { createContext, useContext } from 'react';

export const DialogContext = createContext<{
  onCancel: () => void;
  onToggle?: () => void;
}>({
  onCancel: () => undefined,
});

export const useDialogContext = () => useContext(DialogContext);
