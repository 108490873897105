import { readableColor } from 'polished';
import styled from 'styled-components';
import { COLORS } from '@/constants/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { safePolished } from '@/utils/safePolished';

export const Container = styled.div<{ $isVisible: boolean }>`
  ${({ theme, $isVisible }) => `
    --contextual-cart-container__background-color: ${theme.global.body_color || COLORS.BODY};
    --contextual-cart-container__border-color: ${
      theme.global.primary_border_color || COLORS.LIGHTGRAY
    };

    --contextual-cart-container__translate-y: ${$isVisible ? '0%' : '100%'};
  `}

  background-color: var(--contextual-cart-container__background-color);
  border-top: 1px solid var(--contextual-cart-container__border-color);
  height: 100%;
  bottom: 0;
  height: var(--size-20);
  overflow: hidden;
  padding: var(--size-4);
  position: sticky;
  transform: translateY(var(--contextual-cart-container__translate-y));
  transition: all 0.3s cubic-bezier(0.87, 0, 0.13, 1);
  width: 100%;
`;

export const CartButton = styled.button<{ isAdding: boolean }>(({ theme, isAdding }) => ({
  alignItems: 'center',
  backgroundColor: isAdding
    ? theme.global.success_color
    : theme.buttons.primary_button_background_color,
  borderColor: isAdding ? theme.global.success_color : theme.buttons.primary_button_border_color,
  borderRadius: `${theme.buttons.primary_button_border_radius}px`,
  borderStyle: 'solid',
  borderWidth: 1,
  display: 'grid',
  gridTemplateColumns: 'var(--size-8) 1fr var(--size-8)',
  height: `${theme.forms.field_height}px`,
  padding: '0 var(--size-3) 0 var(--size-3)',
  transition: 'all .3s cubic-bezier(0.87, 0, 0.13, 1)',
  width: '100%',
  ...getFontStyles(theme.buttons.primary_font, {
    color: (value) => {
      if (value === 'revert' || value === null) {
        return safePolished(readableColor)(theme.buttons.primary_button_background_color);
      }

      return value;
    },
    font_size: () => {
      return theme.forms.font.font_size;
    },
  }),
}));

export const CartIcon = styled.div<{ isAdding: boolean }>`
  ${({ isAdding }) => `
    --contextual-cart-icon__default__opacity: ${isAdding ? 0 : 1};
    --contextual-cart-icon__adding__opacity: ${isAdding ? 1 : 0};
  `}

  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 1;
  height: var(--size-6);

  svg,
  img {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    transition: opacity 0.3s cubic-bezier(0.87, 0, 0.13, 1);
  }

  svg,
  img {
    height: var(--size-6);
    object-fit: contain;
    width: var(--size-6);
  }

  svg:nth-child(1),
  img:nth-child(1) {
    opacity: var(--contextual-cart-icon__default__opacity);
  }

  svg:nth-child(2),
  img:nth-child(2) {
    opacity: var(--contextual-cart-icon__adding__opacity);
  }
`;

export const CartText = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  overflow: hidden;

  span {
    display: block;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    transition: all 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }
`;

export const CartTextDefault = styled.span<{ isAdding: boolean }>`
  ${({ isAdding }) => `
    --contextual-cart-text-default__translateY: ${isAdding ? '-100%' : '0%'};
  `}

  transform: translateY(var(--contextual-cart-text-default__translateY));
`;

export const CartTextAdding = styled.span<{ isAdding: boolean }>`
  ${({ isAdding }) => `
    --contextual-cart-text-adding__translateY: ${isAdding ? '0%' : '100%'};
  `}

  transform: translateY(var(--contextual-cart-text-adding__translateY));
`;

export const CartCount = styled.span<{ $isVisible: boolean }>`
  ${({ $isVisible }) => `
    --contextual-cart-count__scale: ${$isVisible ? 1 : 0};
    --contextual-cart-count__opacity: ${$isVisible ? 1 : 0};
  `}

  align-items: center;
  display: flex;
  height: var(--size-8);
  font-weight: 700;
  justify-content: center;
  position: relative;
  width: var(--size-8);

  &:before {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    content: '';
    height: 100%;
    position: absolute;
    transform: scale(var(--contextual-cart-count__scale));
    transition: transform 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    width: 100%;
    z-index: 0;
  }

  & > span {
    opacity: var(--contextual-cart-count__opacity);
    position: relative;
    transition: opacity 0.4s 0.15s cubic-bezier(0.87, 0, 0.13, 1);
    z-index: 1;
  }
`;
