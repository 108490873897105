import { type BasketOrder, type MenuProduct } from '@koala/sdk';
import { useEffect } from 'react';
import styled from 'styled-components';
import { deriveCrossSellParameters, getfilteredCrossSells } from './cross-sells.helpers';
import { Upsell } from './item';
import { Heading } from '@/components/cart/text';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { Box } from '@/components/uielements/box/box';
import { useMenu } from '@/features/menu/service';
import { useDispatch, useSelector } from '@/redux';
import { selectConveyance } from '@/redux/conveyanceMode/reducer';
import { upsellsActions } from '@/redux/upsells/actions';
import { augmentMenuProduct } from '@/utils/menu';

interface Props {
  basketContent: BasketOrder;
  onProductVisibility: ({ product, id }: { product: MenuProduct; id: string }) => void;
}

export const CrossSells = ({ basketContent, onProductVisibility }: Props) => {
  const { max_cross_sells } = useSelector((state) => state.app.cmsConfig.webConfig.sidecart);
  const data = useSelector((state) => state.app.upsells.detail);
  const foodHallEnabled = useSelector((state) => state.app.cmsConfig.webConfig.admin.food_hall);
  const { time_wanted } = useSelector(selectConveyance);
  const { categories } = useMenu({
    id: basketContent.location.id,
    wantedAt: time_wanted,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (basketContent?.basket_items.length) {
      dispatch(
        // @TODO: migrate this redux slice into a react query hook
        upsellsActions.fetchUpsells({
          ...deriveCrossSellParameters(basketContent, max_cross_sells),
          wanted_at: time_wanted,
        }),
      );
    }
  }, []);

  useEffect(() => {
    // Re-fetch cross-sells if basket items have been updated
    if (basketContent?.basket_items.length) {
      dispatch(
        upsellsActions.fetchUpsells(deriveCrossSellParameters(basketContent, max_cross_sells)),
      );
    }
  }, [basketContent?.basket_items.length]);

  useEffect(() => {
    // The precondition for re-fetching cross-sells is that items exist in the basket
    if (basketContent?.basket_items.length) {
      dispatch(
        upsellsActions.fetchUpsells(deriveCrossSellParameters(basketContent, max_cross_sells)),
      );
    }
  }, [basketContent.location.id]);

  const filteredItems = getfilteredCrossSells(data, foodHallEnabled);

  // Canonical upsells array for both the foodHallEnabled / disabled cases
  if (!filteredItems?.length) {
    return null;
  }

  return (
    <Box
      overrides={{
        backgroundColor: 'var(--sidecart__secondary-background-color)',
        borderBlockStart: '1px solid var(--sidecart__divider-color)',
        flex: '1',
        padding: '0 var(--size-5)',
        position: 'relative',
      }}
      data-css-override="StyledCheckoutUpsell"
    >
      <Box
        overrides={{
          padding: 'var(--size-4) 0',
        }}
      >
        <Heading>
          <StringAccessor accessor="cross_sell.header" html={true} />
        </Heading>
      </Box>

      <CrossSellItemsContainer>
        {filteredItems.slice(0, max_cross_sells).map((upsell, arrayIndex) => {
          // Only support the Add On upsell type for now, others will require special cart actions.
          if (upsell.type === 'addon') {
            return upsell.products.map(augmentMenuProduct).map((item, index) => (
              <Upsell
                key={item.id}
                item={item}
                id={arrayIndex + index}
                upsellId={data?.id}
                // menu categories are passed all the way through to the setCrossSellQuickAdd saga in order to add cross sells correctly
                menuCategories={categories}
                onView={(product) => {
                  onProductVisibility({ product, id: data?.id ?? '0' });
                }}
              />
            ));
          }
        })}
      </CrossSellItemsContainer>
    </Box>
  );
};

const CrossSellItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--size-2);
`;
