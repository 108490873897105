import { type MenuProductOption } from '@koala/sdk';
import {
  StyledModifierImage,
  StyledOptionContent,
  StyledOptionLabel,
  StyledOptionName,
} from '../styles';
import { AllergensMenuCard } from '@/components/allergens';
import { ALLERGEN_CARD_MODE } from '@/constants/global';
import { useSelector } from '@/redux';
import { invertNumberIfOptionInverted, toDollars } from '@/utils/basket';
import { resizeImage } from '@/utils/imageHelper';
import { deriveCalorieDisplay } from '@/utils/menu';

interface Props {
  option: MenuProductOption;
  supportsQuantitySelection: boolean;
}
export function Option({ option, supportsQuantitySelection }: Props) {
  const { webConfig } = useSelector(({ app }) => app.cmsConfig);
  const dietaryPreferencesEnabled = webConfig.menu.dietary_preferences_enabled;

  const validOptionCost = option.cost > 0 || option.cost < 0;
  const { cost, calories } = option;

  // Prep calorie parsing logic
  const { calories_separator, is_inverted, max_calories } = option;
  const calorieString: string = deriveCalorieDisplay(
    calories,
    max_calories,
    /** @TODO ensure that `calories_separator` is defined. */
    // @ts-expect-error
    calories_separator,
    is_inverted,
  );

  // Does this option have an image associated with it?
  const hasImage = Boolean(option?.images?.image_url_1_by_1);

  return (
    <>
      {hasImage && (
        <StyledModifierImage
          className="modifier-image"
          /** @TODO differentiate between `null`  and `undefined`. */
          // @ts-expect-error
          url={option?.images && resizeImage(option?.images?.image_url_1_by_1, { height: 200 })}
          supportsQuantitySelection={supportsQuantitySelection}
        />
      )}

      <StyledOptionContent
        data-css-override="StyledOptionContent"
        selected={!!option.selected}
        $is_inverted={!!option.is_inverted}
        $groupHasImages={hasImage}
      >
        <StyledOptionName>
          <p>{option.is_inverted ? option.name_inverted : option.name}</p>
          <StyledOptionLabel>
            {option.labels.map((label) => (
              <img src={label.image_url} alt={label.image_description} key={label.name} />
            ))}
          </StyledOptionLabel>
        </StyledOptionName>

        {option.description && <p style={{ marginBottom: 10 }}>{option.description}</p>}
        {dietaryPreferencesEnabled && (
          <AllergensMenuCard productAllergens={option} mode={ALLERGEN_CARD_MODE.OPTIONS} />
        )}

        {(calorieString || validOptionCost) && (
          <p>
            {calorieString}
            {calorieString && validOptionCost ? ' | ' : ''}
            {validOptionCost
              ? `$${toDollars(invertNumberIfOptionInverted(cost, option.is_inverted ?? false))}`
              : ''}
          </p>
        )}
      </StyledOptionContent>
    </>
  );
}
