import { type Location, type MenuProduct } from '@koala/sdk';
import {
  StyledCustomizeProductDetail,
  StyledCustomizeProductInfo,
  StyledProductImage,
  StyledWarningLabels,
} from '../styles';
import { AllergensMenuCard } from '@/components/allergens';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import MarketingTag from '@/components/store/marketingTag';
import { CSS_CLASSES } from '@/constants/cssClassNames';
import { ALLERGEN_CARD_MODE } from '@/constants/global';
import { MARKETING_TAG_POSITION } from '@/constants/tags';
import { useSelector } from '@/redux';
import { fireGaEvent, gaActions, gaCats } from '@/utils/googleAnalytics';
import { getProductImageSrc, hasProductImage } from '@/utils/imageHelper';
import { safelyGetConfig, safelyGetString } from '@/utils/stringHelpers';
import { useConfigOverride } from '@/redux/cmsConfig';

interface Props {
  product: MenuProduct;
  locationDetail: Location;
}

export function ProductDetail({ product, locationDetail }: Props) {
  const { strings, webConfig } = useSelector(({ app }) => app.cmsConfig);
  const { nutrition_url } = useSelector(({ app }) => app.organization.organization);
  const isImageShown = hasProductImage(product);
  const dietaryPreferencesEnabled = safelyGetConfig(webConfig, 'menu.dietary_preferences_enabled');
  const productMarketingTag = product.marketing_tags && product.marketing_tags[0];
  const { hide_image } = useConfigOverride('menu_product_card');

  // Split product description into lines
  const parsedProductDescription = product.description?.split('\n');

  return (
    <StyledCustomizeProductDetail $customizable={product.customizable} id="Item-Detail">
      {/* Conditionally display product image */}
      {!hide_image && isImageShown && (
        <StyledProductImage
          data-css-override="StyledProductImage"
          src={getProductImageSrc(product, {
            width: 900,
          })}
        />
      )}

      {/* General product details */}
      <StyledCustomizeProductInfo
        id="Item-Detail-Header"
        className={CSS_CLASSES.PRODUCT_DETAIL.PRODUCT_INFO}
      >
        <div>
          {/* Marketing Tag, if the product has one */}
          {productMarketingTag && (
            <MarketingTag tag={productMarketingTag} position={MARKETING_TAG_POSITION.PDP} />
          )}
        </div>
        <div>
          <br />
          <h2>
            {product.name}
            <StyledWarningLabels>
              {product.labels &&
                product.labels.map(
                  (
                    label: {
                      image_description: string;
                      image_url: string;
                      name: string;
                    },
                    i: number,
                  ) => <img src={label.image_url} alt={label.image_description} key={i} />,
                )}
            </StyledWarningLabels>
          </h2>
        </div>
        {parsedProductDescription &&
          parsedProductDescription.map((line, index) => <p key={`line-${index}`}>{line.trim()}</p>)}
        {nutrition_url && safelyGetString(strings, 'store.nutrition_url_cta') && (
          <p>
            <a
              href={nutrition_url}
              onClick={() =>
                fireGaEvent(gaCats.nutrition, gaActions.nutritionInfo, {
                  label: product.name,
                })
              }
              target="_blank"
              rel="noreferrer"
            >
              <StringAccessor
                accessor="store.nutrition_url_cta"
                html={true}
                dataObj={locationDetail}
              />
            </a>
          </p>
        )}
        {/* Product Allergens */}
        {dietaryPreferencesEnabled && (
          <AllergensMenuCard productAllergens={product} mode={ALLERGEN_CARD_MODE.DETAIL} />
        )}
      </StyledCustomizeProductInfo>
    </StyledCustomizeProductDetail>
  );
}
