import { type MenuProductLabel } from '@koala/sdk';
import { StyledWarningLabel } from '../styles';

interface Props {
  labels: MenuProductLabel[];
}

export function WarningLabels({ labels }: Props) {
  if (labels.length === 0) {
    return null;
  }
  return (
    <StyledWarningLabel>
      {labels.map((label) => (
        <div key={label.name}>
          <img src={label.image_url} alt={label.image_description} />
          {/* Modifier description allows HTML in OLO */}
          <p dangerouslySetInnerHTML={{ __html: label.description }}></p>
        </div>
      ))}
    </StyledWarningLabel>
  );
}
