import styled from 'styled-components';
import { StyledPrimaryButton } from '../uielements/primaryButton/styles';
import { LAYOUT } from '@/constants/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';

export const StyledConfirm = styled.div(({ theme }) => ({
  padding: '40px 60px',

  h2: {
    margin: '0 0 10px',
    ...getFontStyles(theme.modals.secondary_font),
  },

  p: {
    margin: '0 0 10px',
  },

  '@media (max-width: 767px)': {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
}));

export const StyledContinueButton = styled(StyledPrimaryButton)`
  font-size: 16px;
  font-weight: 900;
  border-radius: 5px;
  min-height: 50px;
  margin: ${LAYOUT.GUTTER}px auto;
  min-width: 300px;

  @media (max-width: 767px) {
    min-width: 0;
    width: 100%;
  }
`;

export const StickWithButton = styled.button(({ theme }) => ({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  textDecoration: 'underline',
  transition: 'opacity 0.2s linear',
  ...getFontStyles(theme.modals.font),

  '&:hover': {
    opacity: 0.7,
  },
}));
