import { type TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
}

export interface BooleanFilter {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilter>;
}

export interface DateTimeNullableFilter {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Scalars['DateTime'][]>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeNullableFilter>;
  notIn?: InputMaybe<Scalars['DateTime'][]>;
}

export interface IdFilter {
  equals?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Scalars['ID'][]>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  not?: InputMaybe<IdFilter>;
  notIn?: InputMaybe<Scalars['ID'][]>;
}

export interface KeystoneAdminMeta {
  __typename?: 'KeystoneAdminMeta';
  enableSessionItem: Scalars['Boolean'];
  enableSignout: Scalars['Boolean'];
  list?: Maybe<KeystoneAdminUiListMeta>;
  lists: KeystoneAdminUiListMeta[];
}

export interface KeystoneAdminMetaListArgs {
  key: Scalars['String'];
}

export interface KeystoneAdminUiFieldMeta {
  __typename?: 'KeystoneAdminUIFieldMeta';
  createView: KeystoneAdminUiFieldMetaCreateView;
  customViewsIndex?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  fieldMeta?: Maybe<Scalars['JSON']>;
  isFilterable: Scalars['Boolean'];
  isOrderable: Scalars['Boolean'];
  itemView?: Maybe<KeystoneAdminUiFieldMetaItemView>;
  label: Scalars['String'];
  listView: KeystoneAdminUiFieldMetaListView;
  path: Scalars['String'];
  search?: Maybe<QueryMode>;
  viewsIndex: Scalars['Int'];
}

export interface KeystoneAdminUiFieldMetaItemViewArgs {
  id?: InputMaybe<Scalars['ID']>;
}

export interface KeystoneAdminUiFieldMetaCreateView {
  __typename?: 'KeystoneAdminUIFieldMetaCreateView';
  fieldMode: KeystoneAdminUiFieldMetaCreateViewFieldMode;
}

export enum KeystoneAdminUiFieldMetaCreateViewFieldMode {
  Edit = 'edit',
  Hidden = 'hidden',
}

export interface KeystoneAdminUiFieldMetaItemView {
  __typename?: 'KeystoneAdminUIFieldMetaItemView';
  fieldMode?: Maybe<KeystoneAdminUiFieldMetaItemViewFieldMode>;
}

export enum KeystoneAdminUiFieldMetaItemViewFieldMode {
  Edit = 'edit',
  Hidden = 'hidden',
  Read = 'read',
}

export interface KeystoneAdminUiFieldMetaListView {
  __typename?: 'KeystoneAdminUIFieldMetaListView';
  fieldMode: KeystoneAdminUiFieldMetaListViewFieldMode;
}

export enum KeystoneAdminUiFieldMetaListViewFieldMode {
  Hidden = 'hidden',
  Read = 'read',
}

export interface KeystoneAdminUiListMeta {
  __typename?: 'KeystoneAdminUIListMeta';
  description?: Maybe<Scalars['String']>;
  fields: KeystoneAdminUiFieldMeta[];
  hideCreate: Scalars['Boolean'];
  hideDelete: Scalars['Boolean'];
  initialColumns: Scalars['String'][];
  initialSort?: Maybe<KeystoneAdminUiSort>;
  isHidden: Scalars['Boolean'];
  itemQueryName: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
  labelField: Scalars['String'];
  listQueryName: Scalars['String'];
  pageSize: Scalars['Int'];
  path: Scalars['String'];
  plural: Scalars['String'];
  singular: Scalars['String'];
}

export interface KeystoneAdminUiSort {
  __typename?: 'KeystoneAdminUISort';
  direction: KeystoneAdminUiSortDirection;
  field: Scalars['String'];
}

export enum KeystoneAdminUiSortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface KeystoneMeta {
  __typename?: 'KeystoneMeta';
  adminMeta: KeystoneAdminMeta;
}

export interface LinkList {
  __typename?: 'LinkList';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  links?: Maybe<Scalars['JSON']>;
  organizationId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
}

export interface LinkListCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  links?: InputMaybe<Scalars['JSON']>;
  organizationId?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
}

export interface LinkListOrderByInput {
  createdAt?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  organizationId?: InputMaybe<OrderDirection>;
  slug?: InputMaybe<OrderDirection>;
  title?: InputMaybe<OrderDirection>;
  userId?: InputMaybe<OrderDirection>;
}

export interface LinkListUpdateArgs {
  data: LinkListUpdateInput;
  where: LinkListWhereUniqueInput;
}

export interface LinkListUpdateInput {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  links?: InputMaybe<Scalars['JSON']>;
  organizationId?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
}

export interface LinkListWhereInput {
  AND?: InputMaybe<LinkListWhereInput[]>;
  NOT?: InputMaybe<LinkListWhereInput[]>;
  OR?: InputMaybe<LinkListWhereInput[]>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IdFilter>;
  organizationId?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
}

export interface LinkListWhereUniqueInput {
  id?: InputMaybe<Scalars['ID']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  createLinkList?: Maybe<LinkList>;
  createLinkLists?: Maybe<Maybe<LinkList>[]>;
  createPage?: Maybe<Page>;
  createPages?: Maybe<Maybe<Page>[]>;
  createStagedPage?: Maybe<StagedPage>;
  createStagedPages?: Maybe<Maybe<StagedPage>[]>;
  createVersion?: Maybe<Version>;
  createVersions?: Maybe<Maybe<Version>[]>;
  deleteLinkList?: Maybe<LinkList>;
  deleteLinkLists?: Maybe<Maybe<LinkList>[]>;
  deletePage?: Maybe<Page>;
  deletePages?: Maybe<Maybe<Page>[]>;
  deleteStagedPage?: Maybe<StagedPage>;
  deleteStagedPages?: Maybe<Maybe<StagedPage>[]>;
  deleteVersion?: Maybe<Version>;
  deleteVersions?: Maybe<Maybe<Version>[]>;
  endSession: Scalars['Boolean'];
  updateLinkList?: Maybe<LinkList>;
  updateLinkLists?: Maybe<Maybe<LinkList>[]>;
  updatePage?: Maybe<Page>;
  updatePages?: Maybe<Maybe<Page>[]>;
  updateStagedPage?: Maybe<StagedPage>;
  updateStagedPages?: Maybe<Maybe<StagedPage>[]>;
  updateVersion?: Maybe<Version>;
  updateVersions?: Maybe<Maybe<Version>[]>;
}

export interface MutationCreateLinkListArgs {
  data: LinkListCreateInput;
}

export interface MutationCreateLinkListsArgs {
  data: LinkListCreateInput[];
}

export interface MutationCreatePageArgs {
  data: PageCreateInput;
}

export interface MutationCreatePagesArgs {
  data: PageCreateInput[];
}

export interface MutationCreateStagedPageArgs {
  data: StagedPageCreateInput;
}

export interface MutationCreateStagedPagesArgs {
  data: StagedPageCreateInput[];
}

export interface MutationCreateVersionArgs {
  data: VersionCreateInput;
}

export interface MutationCreateVersionsArgs {
  data: VersionCreateInput[];
}

export interface MutationDeleteLinkListArgs {
  where: LinkListWhereUniqueInput;
}

export interface MutationDeleteLinkListsArgs {
  where: LinkListWhereUniqueInput[];
}

export interface MutationDeletePageArgs {
  where: PageWhereUniqueInput;
}

export interface MutationDeletePagesArgs {
  where: PageWhereUniqueInput[];
}

export interface MutationDeleteStagedPageArgs {
  where: StagedPageWhereUniqueInput;
}

export interface MutationDeleteStagedPagesArgs {
  where: StagedPageWhereUniqueInput[];
}

export interface MutationDeleteVersionArgs {
  where: VersionWhereUniqueInput;
}

export interface MutationDeleteVersionsArgs {
  where: VersionWhereUniqueInput[];
}

export interface MutationUpdateLinkListArgs {
  data: LinkListUpdateInput;
  where: LinkListWhereUniqueInput;
}

export interface MutationUpdateLinkListsArgs {
  data: LinkListUpdateArgs[];
}

export interface MutationUpdatePageArgs {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
}

export interface MutationUpdatePagesArgs {
  data: PageUpdateArgs[];
}

export interface MutationUpdateStagedPageArgs {
  data: StagedPageUpdateInput;
  where: StagedPageWhereUniqueInput;
}

export interface MutationUpdateStagedPagesArgs {
  data: StagedPageUpdateArgs[];
}

export interface MutationUpdateVersionArgs {
  data: VersionUpdateInput;
  where: VersionWhereUniqueInput;
}

export interface MutationUpdateVersionsArgs {
  data: VersionUpdateArgs[];
}

export interface NestedStringFilter {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Scalars['String'][]>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Scalars['String'][]>;
  startsWith?: InputMaybe<Scalars['String']>;
}

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export interface Page {
  __typename?: 'Page';
  content?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  excludeFromSitemap?: Maybe<Scalars['Boolean']>;
  homepage?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  organizationId?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoImage?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
}

export interface PageCreateInput {
  content?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  excludeFromSitemap?: InputMaybe<Scalars['Boolean']>;
  homepage?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoImage?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
}

export interface PageOrderByInput {
  createdAt?: InputMaybe<OrderDirection>;
  excludeFromSitemap?: InputMaybe<OrderDirection>;
  homepage?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  organizationId?: InputMaybe<OrderDirection>;
  seoDescription?: InputMaybe<OrderDirection>;
  seoImage?: InputMaybe<OrderDirection>;
  seoTitle?: InputMaybe<OrderDirection>;
  slug?: InputMaybe<OrderDirection>;
  status?: InputMaybe<OrderDirection>;
  title?: InputMaybe<OrderDirection>;
  userId?: InputMaybe<OrderDirection>;
}

export interface PageRelateToOneForCreateInput {
  connect?: InputMaybe<PageWhereUniqueInput>;
  create?: InputMaybe<PageCreateInput>;
}

export interface PageRelateToOneForUpdateInput {
  connect?: InputMaybe<PageWhereUniqueInput>;
  create?: InputMaybe<PageCreateInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
}

export interface PageUpdateArgs {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
}

export interface PageUpdateInput {
  content?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  excludeFromSitemap?: InputMaybe<Scalars['Boolean']>;
  homepage?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoImage?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
}

export interface PageWhereInput {
  AND?: InputMaybe<PageWhereInput[]>;
  NOT?: InputMaybe<PageWhereInput[]>;
  OR?: InputMaybe<PageWhereInput[]>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  excludeFromSitemap?: InputMaybe<BooleanFilter>;
  homepage?: InputMaybe<BooleanFilter>;
  id?: InputMaybe<IdFilter>;
  organizationId?: InputMaybe<StringFilter>;
  seoDescription?: InputMaybe<StringFilter>;
  seoImage?: InputMaybe<StringFilter>;
  seoTitle?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
}

export interface PageWhereUniqueInput {
  id?: InputMaybe<Scalars['ID']>;
}

export interface Query {
  __typename?: 'Query';
  keystone: KeystoneMeta;
  linkList?: Maybe<LinkList>;
  linkLists?: Maybe<LinkList[]>;
  linkListsCount?: Maybe<Scalars['Int']>;
  page?: Maybe<Page>;
  pages?: Maybe<Page[]>;
  pagesCount?: Maybe<Scalars['Int']>;
  stagedPage?: Maybe<StagedPage>;
  stagedPages?: Maybe<StagedPage[]>;
  stagedPagesCount?: Maybe<Scalars['Int']>;
  version?: Maybe<Version>;
  versions?: Maybe<Version[]>;
  versionsCount?: Maybe<Scalars['Int']>;
}

export interface QueryLinkListArgs {
  where: LinkListWhereUniqueInput;
}

export interface QueryLinkListsArgs {
  orderBy?: LinkListOrderByInput[];
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: LinkListWhereInput;
}

export interface QueryLinkListsCountArgs {
  where?: LinkListWhereInput;
}

export interface QueryPageArgs {
  where: PageWhereUniqueInput;
}

export interface QueryPagesArgs {
  orderBy?: PageOrderByInput[];
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PageWhereInput;
}

export interface QueryPagesCountArgs {
  where?: PageWhereInput;
}

export interface QueryStagedPageArgs {
  where: StagedPageWhereUniqueInput;
}

export interface QueryStagedPagesArgs {
  orderBy?: StagedPageOrderByInput[];
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: StagedPageWhereInput;
}

export interface QueryStagedPagesCountArgs {
  where?: StagedPageWhereInput;
}

export interface QueryVersionArgs {
  where: VersionWhereUniqueInput;
}

export interface QueryVersionsArgs {
  orderBy?: VersionOrderByInput[];
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: VersionWhereInput;
}

export interface QueryVersionsCountArgs {
  where?: VersionWhereInput;
}

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive',
}

export interface StagedPage {
  __typename?: 'StagedPage';
  content?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  organizationId?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoImage?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
}

export interface StagedPageCreateInput {
  content?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoImage?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
}

export interface StagedPageOrderByInput {
  createdAt?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  organizationId?: InputMaybe<OrderDirection>;
  seoDescription?: InputMaybe<OrderDirection>;
  seoImage?: InputMaybe<OrderDirection>;
  seoTitle?: InputMaybe<OrderDirection>;
  title?: InputMaybe<OrderDirection>;
  userId?: InputMaybe<OrderDirection>;
}

export interface StagedPageUpdateArgs {
  data: StagedPageUpdateInput;
  where: StagedPageWhereUniqueInput;
}

export interface StagedPageUpdateInput {
  content?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoImage?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
}

export interface StagedPageWhereInput {
  AND?: InputMaybe<StagedPageWhereInput[]>;
  NOT?: InputMaybe<StagedPageWhereInput[]>;
  OR?: InputMaybe<StagedPageWhereInput[]>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IdFilter>;
  organizationId?: InputMaybe<StringFilter>;
  seoDescription?: InputMaybe<StringFilter>;
  seoImage?: InputMaybe<StringFilter>;
  seoTitle?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
}

export interface StagedPageWhereUniqueInput {
  id?: InputMaybe<Scalars['ID']>;
}

export interface StringFilter {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Scalars['String'][]>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Scalars['String'][]>;
  startsWith?: InputMaybe<Scalars['String']>;
}

export interface Version {
  __typename?: 'Version';
  content?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  excludeFromSitemap?: Maybe<Scalars['Boolean']>;
  homepage?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  organizationId?: Maybe<Scalars['String']>;
  page?: Maybe<Page>;
  seoDescription?: Maybe<Scalars['String']>;
  seoImage?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
}

export interface VersionCreateInput {
  content?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  excludeFromSitemap?: InputMaybe<Scalars['Boolean']>;
  homepage?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<PageRelateToOneForCreateInput>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoImage?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
}

export interface VersionOrderByInput {
  createdAt?: InputMaybe<OrderDirection>;
  excludeFromSitemap?: InputMaybe<OrderDirection>;
  homepage?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  organizationId?: InputMaybe<OrderDirection>;
  seoDescription?: InputMaybe<OrderDirection>;
  seoImage?: InputMaybe<OrderDirection>;
  seoTitle?: InputMaybe<OrderDirection>;
  slug?: InputMaybe<OrderDirection>;
  status?: InputMaybe<OrderDirection>;
  title?: InputMaybe<OrderDirection>;
  userId?: InputMaybe<OrderDirection>;
}

export interface VersionUpdateArgs {
  data: VersionUpdateInput;
  where: VersionWhereUniqueInput;
}

export interface VersionUpdateInput {
  content?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  excludeFromSitemap?: InputMaybe<Scalars['Boolean']>;
  homepage?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<PageRelateToOneForUpdateInput>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoImage?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
}

export interface VersionWhereInput {
  AND?: InputMaybe<VersionWhereInput[]>;
  NOT?: InputMaybe<VersionWhereInput[]>;
  OR?: InputMaybe<VersionWhereInput[]>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  excludeFromSitemap?: InputMaybe<BooleanFilter>;
  homepage?: InputMaybe<BooleanFilter>;
  id?: InputMaybe<IdFilter>;
  organizationId?: InputMaybe<StringFilter>;
  page?: InputMaybe<PageWhereInput>;
  seoDescription?: InputMaybe<StringFilter>;
  seoImage?: InputMaybe<StringFilter>;
  seoTitle?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
}

export interface VersionWhereUniqueInput {
  id?: InputMaybe<Scalars['ID']>;
}

export type PageQueryQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export interface PageQueryQuery {
  __typename?: 'Query';
  pages?:
    | {
        __typename?: 'Page';
        id: string;
        title?: string | null;
        slug?: string | null;
        createdAt?: any | null;
        status?: string | null;
        excludeFromSitemap?: boolean | null;
        homepage?: boolean | null;
        content?: any | null;
        seoTitle?: string | null;
        seoDescription?: string | null;
        seoImage?: string | null;
      }[]
    | null;
}

export type HomePageQueryQueryVariables = Exact<Record<string, never>>;

export interface HomePageQueryQuery {
  __typename?: 'Query';
  pages?:
    | {
        __typename?: 'Page';
        id: string;
        title?: string | null;
        slug?: string | null;
        createdAt?: any | null;
        status?: string | null;
        excludeFromSitemap?: boolean | null;
        homepage?: boolean | null;
        content?: any | null;
        seoTitle?: string | null;
        seoDescription?: string | null;
        seoImage?: string | null;
      }[]
    | null;
}

export type HasHomePageQueryQueryVariables = Exact<Record<string, never>>;

export interface HasHomePageQueryQuery {
  __typename?: 'Query';
  pages?: { __typename?: 'Page'; id: string }[] | null;
}

export type SiteIndexedPageQueryQueryVariables = Exact<Record<string, never>>;

export interface SiteIndexedPageQueryQuery {
  __typename?: 'Query';
  pages?: { __typename?: 'Page'; slug?: string | null }[] | null;
}

export type StagedPageQueryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export interface StagedPageQueryQuery {
  __typename?: 'Query';
  stagedPage?: {
    __typename?: 'StagedPage';
    title?: string | null;
    content?: any | null;
  } | null;
}

export type LinkListQueryQueryVariables = Exact<Record<string, never>>;

export interface LinkListQueryQuery {
  __typename?: 'Query';
  linkLists?:
    | {
        __typename?: 'LinkList';
        id: string;
        title?: string | null;
        slug?: string | null;
        links?: any | null;
      }[]
    | null;
}

export const PageQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pageQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'equals' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'slug' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'equals' },
                            value: {
                              kind: 'StringValue',
                              value: 'publish',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'excludeFromSitemap' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'homepage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seoTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'seoDescription' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'seoImage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageQueryQuery, PageQueryQueryVariables>;
export const HomePageQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'homePageQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'homepage' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'equals' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'excludeFromSitemap' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'homepage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seoTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'seoDescription' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'seoImage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomePageQueryQuery, HomePageQueryQueryVariables>;
export const HasHomePageQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'hasHomePageQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'homepage' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'equals' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HasHomePageQueryQuery, HasHomePageQueryQueryVariables>;
export const SiteIndexedPageQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'siteIndexedPageQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeFromSitemap' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'equals' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'equals' },
                            value: {
                              kind: 'StringValue',
                              value: 'publish',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SiteIndexedPageQueryQuery, SiteIndexedPageQueryQueryVariables>;
export const StagedPageQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'stagedPageQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stagedPage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StagedPageQueryQuery, StagedPageQueryQueryVariables>;
export const LinkListQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'linkListQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkLists' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'links' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LinkListQueryQuery, LinkListQueryQueryVariables>;
