import Link from 'next/link';
import { useEffect, useState } from 'react';
import { styled } from '@/components/content/stitches';
import Icon from '@/components/uielements/icon';
import { KOALA_SESSION_STORAGE_KEYS } from '@/constants/global';
import { ROUTES } from '@/constants/routes';
import { useSelector } from '@/redux';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { supportsBrandId } from '@/utils/config';
import { getLocationId } from '@/utils/locations';

interface LocationSessionStorage {
  location_id?: string;
  location_brand_id?: string;
  location_label?: string;
}

export function BackToMenu() {
  const { location } = useSelector((state) => state.app.basket);
  const { webConfig } = useSelector((state) => state.app.cmsConfig);
  const config = useSelector((state) => state.app.cmsConfig.webConfig);

  const [sessionStorageLocation, setSessionStorageLocation] = useState<LocationSessionStorage>();

  useEffect(() => {
    const meta = sessionStorage.getItem(KOALA_SESSION_STORAGE_KEYS.METADATA);

    if (meta) {
      setSessionStorageLocation(JSON.parse(meta));
    }
  }, []);

  let locationId: string | number | undefined = undefined;
  if (location) {
    locationId = getLocationId(location, webConfig);
  } else if (sessionStorageLocation) {
    locationId = supportsBrandId(webConfig)
      ? sessionStorageLocation.location_brand_id
      : sessionStorageLocation.location_id;
  }

  const locationLabel = location.label || sessionStorageLocation?.location_label;

  // 1) If basket exists, route to basket location
  // 2) If no basket, check session storage
  // 3) If no to both, route to homepage
  const route = locationId ? `/store/${locationId}/${locationLabel}` : ROUTES.HOMEPAGE;
  const ctaLabel = locationId ? 'Menu' : 'Back';

  return (
    <Link href={route} passHref={true} legacyBehavior>
      <Container
        css={{
          color: config.header.active_background_color ?? 'inherit',
          '@md': {
            borderLeft: `1px solid ${config.header.border_color}`,
          },
        }}
      >
        {config.header.back_to_menu_icon ? (
          <Image
            src={config.header.back_to_menu_icon}
            role="presentation"
            alt="Back to Menu Icon"
          />
        ) : (
          <Icon.BackEnclosed title="go back" />
        )}

        <Label
          style={{
            ...getFontStyles(config.header.font, ['color']),
          }}
        >
          {ctaLabel}
        </Label>
      </Container>
    </Link>
  );
}

const Container = styled('a', {
  alignItems: 'center',
  display: 'flex',
  gap: '$1',
  justifyContent: 'center',
  width: '100%',

  '@md': {
    padding: '0 0 0 $5',
    width: 'auto',
  },
});

const Image = styled('img', {
  height: '$6',
  objectFit: 'contain',
  width: '$6',
});

const Label = styled('span', {
  display: 'none',

  '@md': {
    display: 'block',
  },
});
