import Link from 'next/link';
import { type ReactNode } from 'react';
import { Item as Container, Caret } from './styles';

interface ItemProps {
  href: string;
  children: ReactNode;
  onClick?: () => void;
}

const Item = ({ href, children, ...props }: ItemProps) => (
  <Link href={href} legacyBehavior>
    <Container {...props}>
      {children}

      <Caret aria-hidden title="Caret" />
    </Container>
  </Link>
);

export default Item;
