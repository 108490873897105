import { type SVGProps } from 'react';
import { type IconProps } from './types';

export const Tiktok = ({ title, ...otherProps }: IconProps & SVGProps<SVGSVGElement>) => (
  <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title>{title}</title>

    <path
      d="M14.9327 5.34784L14.5852 4.80005H12.4828V9.73371L12.4756 14.5528C12.4792 14.5886 12.4828 14.628 12.4828 14.6638C12.4828 15.8704 11.5014 16.8549 10.2908 16.8549C9.08022 16.8549 8.09884 15.8739 8.09884 14.6638C8.09884 13.4572 9.08022 12.4726 10.2908 12.4726C10.5415 12.4726 10.7851 12.5192 11.0107 12.598V10.192C10.7779 10.1526 10.538 10.1311 10.2908 10.1311C7.7944 10.1347 5.76001 12.1683 5.76001 14.6674C5.76001 17.1664 7.7944 19.2 10.2944 19.2C12.7944 19.2 14.8288 17.1664 14.8288 14.6674V8.9353C15.735 9.84112 16.9062 10.7255 18.2027 11.0083V8.54863C16.7951 7.92566 15.3947 6.08538 14.9327 5.34784Z"
      fill="currentColor"
    />
  </svg>
);
