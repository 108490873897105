import { type MenuProductOptionGroup } from '@koala/sdk';
import { StyledOptionQuantityHelperText } from '../styles';
import { setOptionQuantityRequirementsText } from '@/utils/menu';

interface Props {
  group: MenuProductOptionGroup;
}

export function HelperMessage({ group }: Props) {
  const minSelection = group.min_selections;
  const maxSelection = group.max_selections;
  const minAggregate = group.min_aggregate_quantity;
  const maxAggregate = group.max_aggregate_quantity;

  const optionQuantHelperText = setOptionQuantityRequirementsText(
    minSelection,
    maxSelection,
    minAggregate,
    maxAggregate,
  );

  const showAggregateQuantityCounter =
    /** @TODO ensure that `minAggregate` and `maxAggregate` are defined. */
    /** @ts-expect-error */
    minAggregate > 0 && maxAggregate > 0 && minAggregate === maxAggregate;

  return (
    <StyledOptionQuantityHelperText data-css-override="StyledOptionQuantityHelperText">
      <div>
        {group.explanation_text && <p>{group.explanation_text}</p>}
        {optionQuantHelperText && <p>{optionQuantHelperText}</p>}
      </div>

      {/* Only show counter if minAgg equals maxAgg */}
      {showAggregateQuantityCounter && (
        <p>
          ({group.total_aggregate_quantity}/{maxAggregate} selected)
        </p>
      )}
    </StyledOptionQuantityHelperText>
  );
}
