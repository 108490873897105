import kebabCase from 'lodash/kebabCase';
import { StyledField, StyledInput, StyledFieldError } from '../styles';
import Label from '@/components/uielements/label';

interface ReduxFormFieldProps {
  autoComplete?: string;
  maxLength?: number;
  height?: number;
  width?: string;
  placeholder?: string;
  meta?: any;
  input?: {
    name?: string;
    placeholder?: string;
  };
  type?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  step?: string;

  // will display red outline but not show written error
  noErrorOnTouch?: boolean;
}

export const ReduxFormField = ({
  autoComplete,
  placeholder,
  height,
  type,
  inputMode,
  autoFocus,
  disabled,
  meta: { touched, error },
  noErrorOnTouch,
  input,
  step,
  maxLength,
  width,
}: ReduxFormFieldProps) => (
  <StyledField>
    <Label visuallyHidden={true} htmlFor={input?.name ?? ''}>
      {placeholder}
    </Label>
    <StyledInput
      {...input}
      step={step}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      maxLength={maxLength}
      disabled={disabled}
      height={height}
      placeholder={placeholder}
      type={type || 'text'}
      inputMode={inputMode}
      id={input?.name}
      aria-describedby={`${kebabCase(placeholder)}-form-input`}
      width={width}
    />
    {!noErrorOnTouch && touched && error && (
      <StyledFieldError
        tabIndex={0}
        role="alert"
        id={`${kebabCase(placeholder)}-input-error-message`}
      >
        {error}
      </StyledFieldError>
    )}
  </StyledField>
);
