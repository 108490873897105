import * as Dialog from '@radix-ui/react-dialog';
import { Header, Title, CloseButton } from './title.styles';
import Icon from '@/components/uielements/icon';
import { useModalContext } from '@/components/uielements/modal/context';

export const ModalTitle = ({ children, ...otherProps }: React.HTMLProps<HTMLHeadingElement>) => {
  const { onCancel, dismissible } = useModalContext();

  return (
    <Header>
      {dismissible ? (
        <Dialog.Close asChild>
          <CloseButton onClick={onCancel}>
            <Icon.Close title="Dismiss" focusable={false} />
          </CloseButton>
        </Dialog.Close>
      ) : (
        <span></span>
      )}

      <Title asChild>
        <h2 {...otherProps}>{children}</h2>
      </Title>
    </Header>
  );
};
