import styled, { keyframes } from 'styled-components';
import { CardIcon } from '@/components/checkout/uielements';
import { LAYOUT, COLORS, SPACING_SCALE, ORDER } from '@/constants/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';

interface StyledFormFieldProps {
  width?: string | number;
  $autoHeight?: boolean;
}

export const StyledFormField = styled.div<StyledFormFieldProps>`
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  height: ${({ theme, $autoHeight }) =>
    $autoHeight ? 'auto' : `${theme.forms.field_height + LAYOUT.GUTTER}px`};
  overflow: hidden;
  padding-left: ${LAYOUT.GUTTER}px;
  position: relative;
  width: ${({ width }) => width ?? '50%'};

  &:nth-child(odd) {
    padding-left: 0;
  }

  @media (max-width: 767px) {
    flex-basis: 100%;
    padding-left: 0;
  }
`;

interface StyledInputProps {
  width?: string | number;
}

export const StyledInput = styled.input<StyledInputProps>(({ theme, width }) => ({
  backgroundColor: theme.forms.background_color ?? 'transparent',
  borderRadius: `${theme.forms.border_radius ?? 0}px`,
  border: `1px solid ${theme.forms.border_color ?? 'transparent'}`,
  height: `${theme.forms.field_height}px`,
  marginBottom: '20px',
  padding: '0 12px',
  width: width ?? '100%',
  ...getFontStyles(theme.forms.font, ['color', 'font_family', 'font_size']),

  '@media (max-width: 767px)': {
    fontSize: '16px',
    width: '100%',
  },

  '::-webkit-input-placeholder': {
    /* Chrome/Opera/Safari */
    color: theme.forms.placeholder_color,
  },
  '::-moz-placeholder': {
    /* Firefox 19+ */
    color: theme.forms.placeholder_color,
  },
  ':-ms-input-placeholder': {
    /* IE 10+ */
    color: theme.forms.placeholder_color,
  },
  ':-moz-placeholder': {
    /* Firefox 18- */
    color: theme.forms.placeholder_color,
  },
  '&:focus': {
    borderColor: theme.global.primary_active_color,
  },
  '&:disabled': {
    background: COLORS.LIGHTGRAY,
    cursor: 'not-allowed',
  },
}));

interface StyledFieldErrorProps {
  hidden?: boolean;
}

export const StyledFieldError = styled.div<StyledFieldErrorProps>`
  color: ${({ theme }) => theme.global.error_color};
  position: absolute;
  top: ${({ theme }) => theme.forms.field_height}px;
  font-size: 12px;

  ${({ hidden }) =>
    hidden &&
    `
    display: none;
  `}
`;

export const StyledGenericForm = styled.form({
  alignItems: 'start',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  width: '100%',
});

export const StyledGenericFieldset = styled.fieldset({
  border: 0,
  padding: 0,
  width: '100%',

  '& > legend': {
    opacity: 0.01,
    position: 'absolute',
  },
});

export const StyledFormGroup = styled.div({
  alignItems: 'start',
  border: 0,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  width: '100%',
});

export const StyledField = styled.div({
  position: 'relative',
});

export const FormInput = styled.input({
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  border: '1px solid #e0ded9',
  height: '48px',
  marginBottom: '20px',
  padding: '0 12px',
  width: '100%',
  color: '#231f20',
  fontSize: '16px',
  fontFamily: 'Jost, Arial, Helvetica',
});

export const StyledFieldIcon = styled(CardIcon)({
  margin: 0,
  position: 'absolute',
  right: '10px',
  top: '10px',
});

const moveLoadingIndicator = keyframes({
  from: {
    transform: 'translateX(-100%)',
  },
  to: {
    transform: 'translateX(100%)',
  },
});

export const StyledLoading = styled.div`
  background-color: ${COLORS.MEDIUMGRAY};
  border-radius: 8px;
  padding: ${SPACING_SCALE.XS}px;
  padding-left: 14px;
  height: ${SPACING_SCALE.XL}px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${ORDER.LOADING_SKELETON};

  &:before {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    content: '';
    display: block;
    height: ${SPACING_SCALE.MEDIUM}px;
    position: relative;
    width: 120px;
    z-index: 2;
  }

  &:after {
    animation: ${moveLoadingIndicator} 0.8s ease infinite;
    background: linear-gradient(
      90deg,
      ${COLORS.MEDIUMGRAY} 0%,
      ${COLORS.PRIMARY_NAVY_XX_LIGHT} 47.4%,
      ${COLORS.MEDIUMGRAY} 100%
    );
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    width: 100%;
    z-index: 1;
  }
`;
