import { type MenuProduct, type MenuProductOptionGroup, type MenuProductOption } from '@koala/sdk';
import isEmpty from 'lodash/isEmpty';
import {
  StyledNestedOptionContainer,
  StyledOptionGroup,
  StyledTopOptionContainer,
} from '../styles';
import { OptionCard } from './option-card';
import { OptionGroupHeader } from './option-group-header';
import { useSelector } from '@/redux';

interface Props {
  product: MenuProduct;
}

export function ProductOptions({ product }: Props) {
  const { final, displayOptionsErrors } = useSelector(({ app }) => app.customize);
  // If the product has no option groups, don't show anything.
  if (!product.surfaceable_option_groups || !final) {
    return null;
  }
  return (
    <StyledTopOptionContainer>
      {product.surfaceable_option_groups.map((group: MenuProductOptionGroup) => {
        // Don't display hidden option groups.
        if (group.is_hidden || isEmpty(group.options)) {
          return null;
        }
        return (
          <StyledNestedOptionContainer key={group.id} data-global-id={group.global_id}>
            <OptionGroupHeader
              displayOptionsErrors={displayOptionsErrors}
              final={final}
              group={group}
            />
            <StyledOptionGroup data-testid="option-group">
              {group.options.map((opt: MenuProductOption) => (
                <OptionCard key={opt.id} option={opt} group={group} />
              ))}
            </StyledOptionGroup>
          </StyledNestedOptionContainer>
        );
      })}
    </StyledTopOptionContainer>
  );
}
