import { type ChangeEventHandler } from 'react';
import { StyledFieldError } from '@/components/uielements/form-fields/styles';
import Label from '@/components/uielements/label';
import { StyledSelectContainer } from '@/components/uielements/selectInput/styles';

interface IReduxFormSelect {
  name: string;
  optionsArray: any[];
  handleChange: any;
  autoComplete?: string;
  placeholder?: string;
  padding?: string;
  width?: string;
  defaultValue?: string;
  title?: string;
  meta?: any;
  value?: string;
  titleEnabled?: boolean;
  loadingMessage?: string;
  errorMessage?: string;
  // will display red outline but not show written error
  noErrorOnTouch?: boolean;
}
export const ReduxFormSelect = ({
  autoComplete,
  optionsArray,
  handleChange,
  width,
  defaultValue,
  title,
  titleEnabled,
  name,
  meta,
  placeholder,
  noErrorOnTouch,
  value,
  loadingMessage,
  errorMessage,
}: IReduxFormSelect) => {
  return optionsArray.length > 0 ? (
    <div style={{ position: 'relative', paddingBottom: 20 }}>
      <StyledSelectContainer width={width}>
        <div className="select-style">
          <Label visuallyHidden={true} htmlFor={name}>
            {placeholder || name}
          </Label>
          <select
            autoComplete={autoComplete}
            name={name}
            id={name}
            onChange={handleChange}
            defaultValue={defaultValue}
            value={value}
          >
            {title && (
              <option value="" disabled={!titleEnabled}>
                {title}
              </option>
            )}
            {optionsArray.map((entry, index) => (
              <option
                key={`${entry.value}-${index}` || `${entry}-${index}`}
                value={entry.value || entry}
              >
                {entry.label || entry}
              </option>
            ))}
          </select>
        </div>
      </StyledSelectContainer>
      <StyledFieldError>
        {(meta && !noErrorOnTouch && meta.touched && meta.error) || errorMessage}
      </StyledFieldError>
    </div>
  ) : (
    // Fake dropdown to display loading message
    <StyledSelectContainer width={width}>
      <div className="select-style">
        <select>
          <option>{loadingMessage || ''}</option>
        </select>
      </div>
    </StyledSelectContainer>
  );
};

interface IDefaultSelectProps {
  name: string;
  optionsArray: any[];
  handleChange: ChangeEventHandler<HTMLSelectElement>;
  placeholder?: string;
  title: string;
  value?: string;
  errorMessage?: string;
  disabled?: boolean;
}
export const DefaultSelect = ({
  optionsArray,
  handleChange,
  title,
  name,
  placeholder,
  value,
  errorMessage,
  disabled = false,
}: IDefaultSelectProps) => {
  return (
    <div style={{ position: 'relative', paddingBottom: 20 }}>
      <StyledSelectContainer>
        <div className="select-style">
          <Label visuallyHidden={true} htmlFor={name}>
            {placeholder || name}
          </Label>
          <select name={name} id={name} onChange={handleChange} value={value} disabled={disabled}>
            {title && (
              <option value="" disabled={true}>
                {title}
              </option>
            )}
            {optionsArray.map((entry, index) => (
              <option
                key={`${entry.value}-${index}` || `${entry}-${index}`}
                value={entry.value || entry}
              >
                {entry.label || entry}
              </option>
            ))}
          </select>
        </div>
      </StyledSelectContainer>
      <StyledFieldError>{errorMessage}</StyledFieldError>
    </div>
  );
};
