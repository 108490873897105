import { getFooter } from '@koala/sdk/v4';
import { useQuery } from '@tanstack/react-query';
import { createHttpClient } from './client';
export interface FooterLink {
  url: string;
  text: string;
  external?: boolean;
}

export interface FooterTemplate {
  copyright: string | null;
  background_color: string | null;
  copyright_color: string | null;
  link_color: string | null;
  columns: Record<string, FooterLink[]>;
}

export async function getFooterTemplate(origin: string) {
  const client = createHttpClient({ origin });

  return await getFooter({ client });
}

export function useFooterQuery(origin: string) {
  return useQuery({
    queryKey: ['site-footer'],
    queryFn: () => getFooterTemplate(origin),
    refetchOnWindowFocus: false,
  });
}
