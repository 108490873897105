import { type SVGProps } from 'react';
import { type IconProps } from './types';

export const Cart = ({ title, ...otherProps }: IconProps & SVGProps<SVGSVGElement>) => (
  <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title>{title}</title>

    <path
      d="M6.64286 5.37313C6.64286 2.40563 9.04133 0 12 0C14.9587 0 17.3571 2.40563 17.3571 5.37313H20.5714C21.3604 5.37313 22 6.01464 22 6.80597V22.5672C22 23.3585 21.3604 24 20.5714 24H3.42857C2.63959 24 2 23.3585 2 22.5672V6.80597C2 6.01464 2.63959 5.37313 3.42857 5.37313H6.64286ZM8.85714 5.26829H15.1429C15.1429 3.65185 13.7358 2.34146 12 2.34146C10.2642 2.34146 8.85714 3.65185 8.85714 5.26829Z"
      fill="currentColor"
    />
  </svg>
);
