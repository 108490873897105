import styled from 'styled-components';
import { getPlacement, getDirection, type Placement, type Direction } from './stack.helpers';

interface ContainerProps {
  $align: Placement;
  $position: Placement;
  direction: Direction;
  $gap?: string;
}

export const Container = styled.div<ContainerProps>`
  ${({ $align, $position, direction, $gap }) => `
    --stack-container__flex-direction: ${getDirection(direction)};
    --stack-container__align-items: ${getPlacement($align)};
    --stack-container__justify-content: ${getPlacement($position)};
    ${$gap ?? ''}
  `}

  align-items: var(--stack-container__align-items);
  display: flex;
  flex-direction: var(--stack-container__flex-direction);
  justify-content: var(--stack-container__justify-content);
`;
