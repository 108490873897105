import styled from 'styled-components';
import { getFontStyles } from '@/redux/cmsConfig/utils';

export const Heading = styled.h2(({ theme }) => ({
  fontFamily: 'var(--config-primary-font-family__bold)',
  margin: 0,
  ...getFontStyles(theme.sidecart.secondary_font, {
    color: (color) => {
      if (color === 'revert') {
        return 'var(--sidecart__foreground-color)';
      }

      return color;
    },
    font_size: (size) => {
      if (size === 'revert') {
        return '1rem';
      }

      return size;
    },
  }),
}));

export const Text = styled.p(({ theme }) => ({
  ...getFontStyles(
    theme.sidecart.font,

    {
      color: (color) => {
        if (color === 'revert') {
          return 'var(--sidecart__foreground-color)';
        }

        return color;
      },
      font_size: (size) => {
        if (size === 'revert') {
          return '.875rem';
        }

        return size;
      },
    },
  ),
  margin: 0,
}));
