import { CONVEYANCE_TYPES } from '@koala/sdk/v4';
import { readableColor } from 'polished';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DeliveryDetails } from './delivery-details';
import { DeliveryIcon } from './icons/delivery';
import { PickupIcon } from './icons/pickup';
import { LocationDetails } from './location-details';
import { Box, Stack } from '@/components/ui';
import { Render } from '@/components/uielements/render';
import { API_CONVEYANCE_TYPES } from '@/constants/events';
import { useLocation } from '@/features/locations/service';
import { useDispatch, useSelector } from '@/redux';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import conveyanceModeActions from '@/redux/conveyanceMode/actions';
import { selectConveyance } from '@/redux/conveyanceMode/reducer';
import { safePolished } from '@/utils/safePolished';

interface ConveyanceDetailsProps {
  closeButton: JSX.Element;
}

export function ConveyanceDetails({ closeButton }: ConveyanceDetailsProps) {
  const [activeConveyance, setActiveConveyance] = useState(CONVEYANCE_TYPES.PICKUP);
  const { type, time_wanted, location } = useSelector(selectConveyance);
  const { data, isSuccess } = useLocation(location);
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveConveyance(type);
  }, [type]);

  if (!isSuccess) {
    return null;
  }

  return (
    <Stack gap="$6" direction="vertical" align="leading">
      <ButtonStack>
        <Render condition={data.data.supports_delivery && data.data.supports_pick_up}>
          <div style={{ display: 'flex', gap: '5px' }}>
            <Button
              onClick={() => {
                setActiveConveyance(CONVEYANCE_TYPES.PICKUP);
                dispatch(conveyanceModeActions.clearDeliveryAddress(data.data.id));

                dispatch(
                  conveyanceModeActions.conveyanceModeSet(
                    {
                      type: API_CONVEYANCE_TYPES.PICKUP,
                      details: [],
                    },
                    time_wanted,
                  ),
                );
              }}
              $active={activeConveyance !== CONVEYANCE_TYPES.DELIVERY}
            >
              <PickupIcon />
              Pickup
            </Button>

            <Button
              onClick={() => {
                setActiveConveyance(CONVEYANCE_TYPES.DELIVERY);
              }}
              $active={activeConveyance === CONVEYANCE_TYPES.DELIVERY}
            >
              <DeliveryIcon />
              Delivery
            </Button>
          </div>
        </Render>
        {closeButton}
      </ButtonStack>

      <Box css={{ width: '100%' }}>
        {activeConveyance === CONVEYANCE_TYPES.DELIVERY ? <DeliveryDetails /> : <LocationDetails />}
      </Box>
    </Stack>
  );
}

const Button = styled.button<{ $active: boolean }>(({ theme, $active }) => ({
  all: 'unset',
  boxSizing: 'border-box',
  alignItems: 'center',
  backgroundColor: $active ? theme.buttons.primary_button_background_color : 'transparent',
  border: '1px solid',
  borderColor: $active
    ? theme.buttons.primary_button_border_color
    : theme.header.border_color ?? '#ddd',
  borderRadius: `${theme.buttons.primary_button_border_radius ?? 0}px`,
  cursor: 'pointer',
  display: 'flex',
  gap: '.625rem',
  height: '2.5rem',
  padding: '0.5rem 0.75rem',
  ...getFontStyles(
    theme.buttons.primary_font,
    ['color', 'font_family', 'font_weight', 'line_height'],
    {
      color: (value) => {
        if ($active) {
          // if the value isn't set, use black or white depending on the background color
          if (value === 'revert' || value === null) {
            return safePolished(readableColor)(theme.buttons.primary_button_background_color);
          }

          return value;
        }

        return theme.modals.font.color;
      },
    },
  ),
}));

const ButtonStack = styled.div`
  display: 'flex';
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
