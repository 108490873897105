import { type SVGProps } from 'react';
import { type IconProps } from './types';

export const Twitter = ({ title, ...otherProps }: IconProps & SVGProps<SVGSVGElement>) => (
  <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title>{title}</title>

    <path
      d="M4.79999 16.5252C6.35003 16.6125 7.70549 16.2501 9.02739 15.2906C7.69207 15.0624 6.76607 14.4518 6.26281 13.1903C6.65199 13.1366 6.99421 13.2373 7.39682 13.0762C6.08163 12.4992 5.22944 11.6335 5.19589 10.1372C5.61192 10.1707 5.9273 10.4458 6.42385 10.4056C5.21602 9.22459 4.84696 7.93624 5.60521 6.37278C6.86672 7.82217 8.34296 8.80856 10.148 9.27157C10.2486 9.29841 10.3426 9.32525 10.4432 9.34538C10.9062 9.45274 11.4565 9.68089 11.7383 9.64733C12.2214 9.58694 11.7383 9.03 11.8591 8.3053C12.2416 6.05069 14.7243 5.02403 16.5629 6.34594C17.0997 6.73513 17.5224 6.72842 18.0526 6.49356C18.3277 6.37278 18.6095 6.25199 18.9383 6.11108C18.8645 6.75526 18.3679 7.11089 17.972 7.58732C18.4216 7.68797 18.784 7.56048 19.2 7.42627C19.0591 7.88927 18.7101 8.15097 18.3948 8.40596C18.066 8.66765 17.9385 8.94277 17.9251 9.37222C17.7238 15.8744 10.3359 20.9003 5.28312 16.901C4.79328 16.5118 5.2697 16.901 4.79999 16.5252Z"
      fill="currentColor"
    />
  </svg>
);
