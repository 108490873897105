import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  StyledAccountDropdownContent,
  StyledAccountDropdownNumber,
  StyledDropdownButton,
  StyledDropdownHeaderContent,
  StyledDropdownModalContainer,
  StyledDropdownOverlay,
} from './styles';
import LoyaltyAccessor from '@/components/account/loyaltyAccessor';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { ConnectedXButton } from '@/components/uielements/X';
import { CSS_CLASSES } from '@/constants/cssClassNames';
import { LOYALTY_ACCESSOR_TYPES, LOYALTY_FEATURES } from '@/constants/loyalty';
import { LOYALTY_ROUTES, ROUTES } from '@/constants/routes';
import { useSelector } from '@/redux';
import authActions from '@/redux/auth/actions';
import meActions from '@/redux/me/actions';
import { getActiveRewardsAndOffersLength, getUnreadPersonalMessagesLength } from '@/utils/loyalty';
import { pluralizeString, safelyGetString } from '@/utils/stringHelpers';

export function AccountDropdown() {
  const dispatch = useDispatch();
  const router = useRouter();
  const me = useSelector((state) => state.app.me.data);
  const loyaltyState = useSelector((state) => state.app.me.loyaltyState);
  const myMessages = useSelector((state) => state.app.me.messages);
  const myOffers = useSelector((state) => state.app.me.myOffers);
  const myRedeemables = useSelector((state) => state.app.me.redeemables);
  const accountDropdownOpen = useSelector((state) => state.app.me.accountDropdownOpen);
  const strings = useSelector((state) => state.app.cmsConfig.strings);

  const navigationRef = useRef<HTMLUListElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [listCount, setListCount] = useState(0);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardNavigation);
    document.addEventListener('mousedown', handleClick, false);

    return () => {
      document.removeEventListener('keydown', handleKeyboardNavigation);
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, []);

  useEffect(() => {
    if (!me) {
      // If user signs out from dropdown, hide the dropdown
      dispatch(meActions.toggleAccountDropdown(false));
    }

    if (accountDropdownOpen) {
      setupListNavigation();
    }

    if (navigationRef.current && activeIndex) {
      const linkToFocus = navigationRef.current.querySelector<HTMLAnchorElement>(
        `li:nth-child(${activeIndex}) a`,
      );

      if (linkToFocus) {
        linkToFocus.focus();
      }
    }
  }, [me, accountDropdownOpen, navigationRef.current, activeIndex]);

  // The KeyboardEvent option in Javascript allows us to use an abstracted
  // list of keyboard events that are mapped from various types of keyboards.
  // This list is human readable ("Escape", "ArrowDown", etc)
  const handleKeyboardNavigation = (evt: { key: string }) => {
    // Close modal on 'esc' press
    if (accountDropdownOpen) {
      // space key is mapped as " "
      if (evt.key === 'Escape' || evt.key === ' ') {
        dispatch(meActions.toggleAccountDropdown(false));
      }

      if (evt.key === 'ArrowDown' || evt.key === 'ArrowRight') {
        setActiveIndex(activeIndex < listCount ? activeIndex + 1 : 1);
      }

      if (evt.key === 'ArrowUp' || evt.key === 'ArrowLeft') {
        setActiveIndex(activeIndex > 1 ? activeIndex - 1 : listCount);
      }

      if (evt.key === 'Home') {
        setActiveIndex(1);
      }

      if (evt.key === 'End') {
        setActiveIndex(listCount);
      }
    }
  };

  /** @TODO improve event typing. */
  // @ts-expect-error
  const handleClick = (e) => {
    if (e.target.id !== 'Loyalty-Dropdown-Overlay') {
      return;
    }
    dispatch(meActions.toggleAccountDropdown(false));
  };

  const setupListNavigation = () => {
    if (navigationRef.current) {
      setActiveIndex(1);
      setListCount(navigationRef.current.querySelectorAll('li').length);

      // we want to try and focus the first element
      setTimeout(() => {
        const linkToFocus =
          navigationRef?.current?.querySelector<HTMLAnchorElement>(`li:nth-child(1) a`);

        if (linkToFocus) {
          linkToFocus.focus();
        }
      }, 100);
    }
  };

  // Count unread personal messages
  const unreadPersonalMessagesLength: number = getUnreadPersonalMessagesLength(myMessages);

  // Combine available rewards and offers
  const currentPoints = loyaltyState?.points ?? 0;
  const combinedRewardsLength =
    getActiveRewardsAndOffersLength(myRedeemables, myOffers, currentPoints) || 0;
  const pointsCounter = pluralizeString(
    safelyGetString(strings, 'rewards.rewards_counter'),
    currentPoints !== 1,
  );

  const cancelRedirect = router?.pathname.includes(ROUTES.ACCOUNT);

  return (
    <StyledDropdownOverlay $isVisible={accountDropdownOpen} id="Loyalty-Dropdown-Overlay">
      <StyledDropdownModalContainer
        aria-label="Account and Loyalty"
        $isVisible={accountDropdownOpen}
      >
        <ConnectedXButton
          onClick={() => dispatch(meActions.toggleAccountDropdown(false))}
          id="loyalty-dropdown-x-button"
          name="Close dropdown"
        />
        <StyledAccountDropdownContent>
          <StyledDropdownHeaderContent>
            <h3 id="account-menu-label">Hi, {me?.first_name}!</h3>
            <LoyaltyAccessor
              checkType={LOYALTY_ACCESSOR_TYPES.FEATURE}
              checkName={LOYALTY_FEATURES.SHOW_LOYALTY_STATE}
              component={
                <>
                  {loyaltyState?.membership_level_name && (
                    <p>{loyaltyState?.membership_level_name}</p>
                  )}
                  {currentPoints > 0 && (
                    <StringAccessor
                      accessor="rewards.account_menu_points_subheader"
                      html={true}
                      tag="p"
                      dataObj={{
                        number: currentPoints.toLocaleString(),
                        counter: pointsCounter,
                      }}
                    />
                  )}
                </>
              }
            />
          </StyledDropdownHeaderContent>
          <ul ref={navigationRef}>
            <LoyaltyAccessor
              checkType={LOYALTY_ACCESSOR_TYPES.ROUTE}
              checkName={LOYALTY_ROUTES.REWARDS}
              component={
                <li>
                  <Link href={LOYALTY_ROUTES.REWARDS} passHref={true} legacyBehavior>
                    <StyledDropdownButton
                      aria-label="Go to Rewards page"
                      className={CSS_CLASSES.LOYALTY.DROPDOWN_BUTTON}
                    >
                      <StringAccessor accessor="rewards.title" />
                      {combinedRewardsLength > 0 && (
                        <StyledAccountDropdownNumber>
                          {combinedRewardsLength}
                        </StyledAccountDropdownNumber>
                      )}
                    </StyledDropdownButton>
                  </Link>
                </li>
              }
            />
            <LoyaltyAccessor
              checkType={LOYALTY_ACCESSOR_TYPES.ROUTE}
              checkName={LOYALTY_ROUTES.INBOX}
              component={
                <li>
                  <Link href={LOYALTY_ROUTES.INBOX} passHref={true} legacyBehavior>
                    <StyledDropdownButton
                      aria-label="Go to Inbox page"
                      className={CSS_CLASSES.LOYALTY.DROPDOWN_BUTTON}
                    >
                      <StringAccessor accessor="inbox.title" />
                      {unreadPersonalMessagesLength > 0 && (
                        <StyledAccountDropdownNumber>
                          {unreadPersonalMessagesLength}
                        </StyledAccountDropdownNumber>
                      )}
                    </StyledDropdownButton>
                  </Link>
                </li>
              }
            />
            <LoyaltyAccessor
              checkType={LOYALTY_ACCESSOR_TYPES.ROUTE}
              checkName={LOYALTY_ROUTES.FAVORITES}
              component={
                <li>
                  <Link href={LOYALTY_ROUTES.FAVORITES} passHref={true} legacyBehavior>
                    <StyledDropdownButton
                      aria-label="Go to Favorites page"
                      className={CSS_CLASSES.LOYALTY.DROPDOWN_BUTTON}
                    >
                      <StringAccessor accessor="favorites.title" />
                    </StyledDropdownButton>
                  </Link>
                </li>
              }
            />
            <LoyaltyAccessor
              checkType={LOYALTY_ACCESSOR_TYPES.ROUTE}
              checkName={LOYALTY_ROUTES.ORDER_HISTORY}
              component={
                <li>
                  <Link href={LOYALTY_ROUTES.ORDER_HISTORY} passHref={true} legacyBehavior>
                    <StyledDropdownButton
                      aria-label="Go to Order History page"
                      className={CSS_CLASSES.LOYALTY.DROPDOWN_BUTTON}
                    >
                      <StringAccessor accessor="order_history.title" />
                    </StyledDropdownButton>
                  </Link>
                </li>
              }
            />
            <LoyaltyAccessor
              checkType={LOYALTY_ACCESSOR_TYPES.ROUTE}
              checkName={LOYALTY_ROUTES.ACCOUNT}
              component={
                <li>
                  <Link href={LOYALTY_ROUTES.ACCOUNT} passHref={true} legacyBehavior>
                    <StyledDropdownButton
                      aria-label="Go to Account page"
                      className={CSS_CLASSES.LOYALTY.DROPDOWN_BUTTON}
                    >
                      <StringAccessor accessor="account.title" />
                    </StyledDropdownButton>
                  </Link>
                </li>
              }
            />
            <li>
              <StyledDropdownButton
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(authActions.logout(cancelRedirect));
                }}
                aria-label="Click to Sign Out"
                className={CSS_CLASSES.LOYALTY.DROPDOWN_BUTTON}
              >
                <StringAccessor accessor="account.sign_out_button" />
              </StyledDropdownButton>
            </li>
          </ul>
        </StyledAccountDropdownContent>
      </StyledDropdownModalContainer>
    </StyledDropdownOverlay>
  );
}
