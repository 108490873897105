import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { AncillaryItem, AncillaryNavigation, Version, Logout } from './footer.styles';
import authActions from '@/redux/auth/actions';
import { type RootState } from '@/types/app';
import { getAndroidShellVersion } from '@/utils/android';
import { useAccount } from '@/utils/hooks';
import { safelyGetString } from '@/utils/stringHelpers';

const customRoutes = [
  {
    name: 'route_one_name',
    url: 'route_one_url',
  },
  {
    name: 'route_two_name',
    url: 'route_two_url',
  },
  {
    name: 'route_three_name',
    url: 'route_three_url',
  },
];

interface Props {
  onDismiss: () => void;
}

export const Footer = ({ onDismiss }: Props) => {
  const { loggedIn } = useAccount();
  const { strings } = useSelector((state: RootState) => state.app.cmsConfig);
  const dispatch = useDispatch();

  return (
    <AncillaryNavigation>
      {loggedIn && (
        <Logout
          onClick={() => {
            dispatch(authActions.logout());
            onDismiss();
          }}
        >
          {safelyGetString(strings, 'android_drawer.sign_out')}
        </Logout>
      )}

      <Link
        href={safelyGetString(strings, 'android_drawer.terms_and_conditions_url')}
        legacyBehavior
      >
        <AncillaryItem>
          {safelyGetString(strings, 'android_drawer.terms_and_conditions')}
        </AncillaryItem>
      </Link>

      <Link href={safelyGetString(strings, 'android_drawer.privacy_policy_url')} legacyBehavior>
        <AncillaryItem>{safelyGetString(strings, 'android_drawer.privacy_policy')}</AncillaryItem>
      </Link>

      {/** @TODO route properties can't index `android_drawer` strings. */}
      {customRoutes
        .filter(
          (route) =>
            // @ts-expect-error
            strings.android_drawer[route.name] &&
            // @ts-expect-error
            strings.android_drawer[route.url],
        )
        .map((route) => (
          <>
            {/** @ts-expect-error */}
            <Link href={strings.android_drawer[route.url]} legacyBehavior>
              <AncillaryItem>
                {/** @ts-expect-error */}
                {strings.android_drawer[route.name]}
              </AncillaryItem>
            </Link>
          </>
        ))}

      <Version>{getAndroidShellVersion()}</Version>
    </AncillaryNavigation>
  );
};
