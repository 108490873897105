import {
  StyledNestedOptionContainer,
  StyledOptionGroupHeader,
  StyledTopOptionContainer,
} from '../styles';
import { StyledInput } from '@/components/uielements/form-fields/styles';
import { CSS_CLASSES } from '@/constants/cssClassNames';

interface Props {
  maxLength: number;
  onChange: (value: string) => void;
  title: string;
  value: string;
}

export const Recipient = ({ maxLength, onChange, title, value }: Props) => (
  <StyledTopOptionContainer>
    <StyledNestedOptionContainer>
      <StyledOptionGroupHeader className={CSS_CLASSES.PRODUCT_DETAIL.RECIPIENT_INSTRUCTIONS_HEADER}>
        <h3>{title}</h3>
      </StyledOptionGroupHeader>
      <StyledInput
        placeholder={`Enter recipient (${maxLength} character limit)`}
        maxLength={maxLength}
        onChange={(e) => {
          if (maxLength && e.target.value.length > maxLength) {
            return;
          }
          onChange(e.target.value);
        }}
        value={value}
      />
    </StyledNestedOptionContainer>
  </StyledTopOptionContainer>
);
