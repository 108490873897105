import { useRouter } from 'next/router';
import styled from 'styled-components';
import Icon from '@/components/uielements/icon';
import { setAndroidCurrentPage } from '@/utils/android';

export const AndroidBackButton = () => {
  const router = useRouter();

  return (
    <Element onClick={() => setAndroidCurrentPage(router.pathname, router.back, router.push)}>
      <Icon.Back title="Go back" />
    </Element>
  );
};

const Element = styled.button`
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  color: var(--config-global__primary-active-color, black);
  display: flex;
  justify-content: center;
  padding: 0;
  width: var(--size-12);

  &:active {
    opacity: 0.5;
  }
`;
