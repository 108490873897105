import * as Dialog from '@radix-ui/react-dialog';
import { type ReactNode } from 'react';
import { AccountDetails } from './account-details';
import { Links } from './links';
import { ConnectedPersonIcon } from '@/components/account/uielements';
import { type UrlField } from '@/components/content/modules/types';
import { styled } from '@/components/content/stitches';
import { GenericRouteAccessor } from '@/components/genericRouteAccessor';
import { BackToMenu } from '@/components/header/back';
import { Signin } from '@/components/header/sign-in';
import { Box, Stack } from '@/components/ui';
import Icon from '@/components/uielements/icon';
import { ROUTES } from '@/constants/routes';
import { useConfigOverride } from '@/redux/cmsConfig';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { useAnimatedVisibility } from '@/utils/useAnimatedVisibility';

interface DrawerProps {
  children: ReactNode;
  onDismiss: () => void;
  shouldDisplay: boolean;
  hasNavigation: boolean;
}

function Drawer({
  children,
  onDismiss,
  shouldDisplay = false,
  hasNavigation = false,
}: DrawerProps) {
  return (
    <Panel
      shouldDisplay={shouldDisplay}
      onEscapeKeyDown={onDismiss}
      onPointerDownOutside={onDismiss}
    >
      <Toggle
        onClick={onDismiss}
        css={{
          position: 'sticky',
          top: '0',

          '@md': {
            position: 'relative',
          },
        }}
      >
        <Icon.Close title="Open Navigation Drawer" />
      </Toggle>

      <Box
        css={{
          '@md': {
            display: 'none',
          },
        }}
      >
        <AccountDetails showDetailsByDefault={!hasNavigation} onSignOut={onDismiss} />
      </Box>

      {children}
    </Panel>
  );
}

interface Props {
  links?: {
    order: string[];
    links: Record<string, UrlField>;
  };
  hasNavigation: boolean;
  inlineMenu: boolean;
  signedIn: boolean;
}

export function Navigation({ links, hasNavigation, inlineMenu, signedIn }: Props) {
  const { isMounted, shouldDisplay, toggle, ref } = useAnimatedVisibility();
  const { active_font_color, font } = useConfigOverride('modals');
  const { color: font_color } = getFontStyles(font);

  return (
    <>
      {hasNavigation ? (
        <>
          <Box
            css={{
              '@md': {
                display: inlineMenu ? 'none' : 'block',
              },
            }}
          >
            <Toggle
              onClick={() => {
                toggle();
              }}
            >
              <Icon.Menu title="Open Navigation Drawer" />
            </Toggle>
          </Box>

          {links && inlineMenu && (
            <Stack
              as="nav"
              css={{
                display: 'none',
                gap: '$1 $5',
                order: 2,

                '@md': {
                  display: 'flex',
                  flexWrap: 'wrap',
                },
              }}
            >
              <Links links={links} inlineMenu={inlineMenu} />
            </Stack>
          )}
        </>
      ) : (
        <Box
          css={{
            '@md': {
              // non commerce+ customers should never interact with the navigation drawer on desktop
              display: 'none',
            },
          }}
        >
          <GenericRouteAccessor
            allowedPages={{
              [ROUTES.ACCOUNT]: null,
              [ROUTES.CHECKOUT]: { exactMatch: true },
              [ROUTES.LOGIN]: { exactMatch: true },
              [ROUTES.SIGN_UP]: { exactMatch: true },
            }}
            component={<BackToMenu />}
          />

          <GenericRouteAccessor
            allowedPages={{
              [ROUTES.HOMEPAGE]: { exactMatch: true },
              [ROUTES.STORE]: null,
              [ROUTES.PAGE]: null,
            }}
            component={
              <>
                <Toggle
                  onClick={() => {
                    signedIn && toggle();
                  }}
                >
                  {signedIn ? <ConnectedPersonIcon /> : <Signin />}
                </Toggle>
              </>
            }
          />
        </Box>
      )}

      <Dialog.Root open={isMounted}>
        <Dialog.Portal>
          <Container
            // @ts-expect-error: ref typing consistenly throws an error
            ref={ref}
            css={{
              color: font_color,

              '& a': {
                color: active_font_color,
              },

              '& button': {
                color: font_color,
              },
            }}
          >
            <Overlay shouldDisplay={shouldDisplay} data-testid="sidecart-overlay" />

            <Drawer
              hasNavigation={hasNavigation}
              shouldDisplay={shouldDisplay}
              onDismiss={() => toggle()}
            >
              {links && (
                <LinksContainer>
                  <Links links={links} onDismiss={() => toggle()} />
                </LinksContainer>
              )}
            </Drawer>
          </Container>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}

const Toggle = styled('button', {
  alignItems: 'center',
  appearance: 'none',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  margin: '0 auto 0 $4',
  padding: 0,

  '&:focus': {
    outline: 'none',
  },

  '&:focus-visible': {
    outline: 'inherit',
  },

  '@md': {
    margin: '0 auto 0 0',
  },
});

const Container = styled('div', {
  alignItems: 'stretch',
  bottom: 0,
  display: 'flex',
  justifyContent: 'flex-start',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  transition: 'all 0.6s linear',
  zIndex: '$50',
});

const Overlay = styled(Dialog.Overlay, {
  background: 'rgba(26, 26, 26, 0.16)',
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  transition: 'all 0.4s ease',
  zIndex: '$0',

  variants: {
    shouldDisplay: {
      false: {
        opacity: 0.01,
      },
      true: {
        opacity: 1,
      },
    },
  },
});

const Panel = styled(Dialog.Content, {
  backgroundColor: '$shade200',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'scroll',
  padding: '$6 0 0',
  transition: 'all 0.6s cubic-bezier(0.16, 1, 0.3, 1)',
  width: '$72',
  zIndex: '$10',

  variants: {
    shouldDisplay: {
      false: {
        transform: 'translateX(-100%)',
      },
      true: {
        transform: 'translateX(0%)',
      },
    },
  },

  '@md': {
    backgroundColor: '$shade100',
    padding: '$7 $10 $10',
    width: '$96',
  },

  '&:focus': {
    outline: 'none',
  },
});

const LinksContainer = styled('nav', {
  backgroundColor: '$shade100',
  borderTop: '1px solid $shade300',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '$3 $4 $4',

  '@md': {
    borderTop: 'none',
    padding: '$6 0 0',
  },
});
