export function DeliveryIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Delivery Icon</title>
      <path
        d="M14.5 11.5V13C14.5 13.1326 14.4473 13.2598 14.3536 13.3536C14.2598 13.4473 14.1326 13.5 14 13.5H12.5C12.3674 13.5 12.2402 13.4473 12.1464 13.3536C12.0527 13.2598 12 13.1326 12 13V11.5"
        stroke="currentColor"
        strokeWidth="0.96875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 11.5V13C5 13.1326 4.94732 13.2598 4.85355 13.3536C4.75979 13.4473 4.63261 13.5 4.5 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3536C2.55268 13.2598 2.5 13.1326 2.5 13V11.5"
        stroke="currentColor"
        strokeWidth="0.96875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 9.5H12.5"
        stroke="currentColor"
        strokeWidth="0.96875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 9.5H5.5"
        stroke="currentColor"
        strokeWidth="0.96875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 7.5H15.5"
        stroke="currentColor"
        strokeWidth="0.96875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 7.5L12.632 3.29693C12.5927 3.20855 12.5286 3.13346 12.4475 3.08075C12.3664 3.02805 12.2718 3 12.1751 3H4.82494C4.72822 3 4.63358 3.02805 4.55248 3.08075C4.47138 3.13346 4.40731 3.20855 4.36803 3.29693L2.5 7.5V11.5H14.5V7.5Z"
        stroke="currentColor"
        strokeWidth="0.96875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
