import { createContext, useContext } from 'react';

export const ModalContext = createContext<{
  onCancel: () => void;
  onToggle: () => void;
  dismissible: boolean;
}>({
  onCancel: () => null,
  onToggle: () => null,
  dismissible: true,
});

export const useModalContext = () => useContext(ModalContext);
