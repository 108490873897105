import styled from 'styled-components';

interface Props {
  children: any;
  visuallyHidden: boolean;
  htmlFor: string;
}

const Label = ({ children, visuallyHidden, htmlFor }: Props) => (
  <StyledLabel htmlFor={htmlFor} $visuallyHidden={visuallyHidden}>
    {children}
  </StyledLabel>
);

export const StyledLabel = styled.label<{ $visuallyHidden: boolean }>`
  ${({ $visuallyHidden }) =>
    $visuallyHidden &&
    `
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  `}
`;

export default Label;
