import { parseNumber, type ParsedNumber } from 'libphonenumber-js';

export const validatePhoneNumber = (value: string): boolean => {
  if (/^[\d]+$/i.test(value)) {
    const parsedNumber: {} | ParsedNumber = parseNumber(`+1${value}`, 'US');

    if ('phone' in parsedNumber) {
      return true;
    }
  }

  return false;
};

export const formatPhoneNumber = (phoneNumber: string) =>
  `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(3, 6)}-${phoneNumber?.slice(6)}`;
