import { type MenuProduct, type MenuProductOptionGroup } from '@koala/sdk';
import { StyledOptionGroupHeader, StyledOptionGroupHelperText } from '../styles';
import { HelperMessage } from './helper-message';
import { CSS_CLASSES } from '@/constants/cssClassNames';

function getOptionsStillNeeded(requiredGroup: MenuProductOptionGroup, final: MenuProduct) {
  // Array of option groups that contain our selected options
  const selectedOptionGroups = final.surfaceable_option_groups ?? [];

  // Find the group that has required minimums
  const selectedRequiredGroup = selectedOptionGroups.find((group) => group.id === requiredGroup.id);

  /*
   * Required group's min_aggregate_quantity minus how many options user has selected
   * 'Single selection' items in Olo may have max_aggregate_quantity of 0, which is not intuitive.
   * In this case, we need to take into account min_selections instead
   */
  const minimumRequirements =
    requiredGroup.max_aggregate_quantity === 0
      ? requiredGroup.min_selections
      : requiredGroup.min_aggregate_quantity;
  const optionsStillNeeded =
    /** @TODO ensure that `minimumRequirements` and `total_aggregate_quantity` are defined. */
    // @ts-expect-error
    minimumRequirements - selectedRequiredGroup.total_aggregate_quantity;

  return optionsStillNeeded;
}

interface Props {
  group: MenuProductOptionGroup;
  final: MenuProduct;
  displayOptionsErrors: boolean;
}

export function OptionGroupHeader({ group, final, displayOptionsErrors }: Props) {
  return (
    <StyledOptionGroupHeader
      id={`option-group-${group.id}`}
      className={CSS_CLASSES.STORE_MENU.PRODUCT_OPTION_GROUP_HEADER}
    >
      <h3>
        {group.description.trim()}
        {group.min_selections && group.min_selections > 0 ? <span>Required</span> : ''}
      </h3>
      <HelperMessage group={group} />
      {/* Find a matching option group that's invalid */}
      {final.surfaceable_option_groups?.find(
        (optionGroup) => optionGroup.id === group.id && !optionGroup.valid,
      ) &&
        // If there's at least 1 option still needed
        getOptionsStillNeeded(group, final) > 0 &&
        // and user has clicked Add to Bag
        displayOptionsErrors && (
          // Display helper text
          <StyledOptionGroupHelperText>
            Please make the required selection: Choose {getOptionsStillNeeded(group, final)}
          </StyledOptionGroupHelperText>
        )}
    </StyledOptionGroupHeader>
  );
}
