import { type ReactNode } from 'react';
import Item from './item';
import { Container } from './styles';

interface NavigationProps {
  children?: ReactNode;
}

const List = ({ children }: NavigationProps) => <Container>{children}</Container>;

export const Navigation = {
  List,
  Item,
};
