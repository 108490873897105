import { type DeliveryAddress, CONVEYANCE_TYPES, type WebConfig } from '@koala/sdk';
import { useEffect, useState, Fragment } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';
import { Field, getFormValues, type InjectedFormProps, reduxForm } from 'redux-form';
import { withTheme } from 'styled-components';
import { StyledContinueButton } from '../../styles';
import { addressValidation as validate } from '../deliveryForm/validation';
import { StyledAddAddressButton, StyledCityStateZip, StyledForm } from './styles';
import { genericEventHandler } from '@/analytics/events';
import { GlobalEvents } from '@/analytics/events/constants';
import { StyledRadioLegend, StyledRadioRow, RadioOption } from '@/components/checkout/uielements';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import GoogleAutocomplete from '@/components/fulfillmentManager/deliveryManager/deliveryForm/autocomplete';
import { StyledDeliveryFormSubheader } from '@/components/locations/search/storedAddresses/styles';
import { ReduxFormField } from '@/components/uielements/form-fields';
import { Render } from '@/components/uielements/render';
import { ReduxFormSelect } from '@/components/uielements/selectCheckout';
import { stateAbbrMappings } from '@/constants/states';
import { HandoffTimePicker } from '@/features/handoff/time-picker';
import conveyanceModeActions from '@/redux/conveyanceMode/actions';
import globalActions from '@/redux/global/actions';
import { locationsActions } from '@/redux/locations/actions';
import meActions from '@/redux/me/actions';
import { type RootState } from '@/types/app';
import { userLoggedIn } from '@/utils/auth';
import { showManualDeliveryFormConditions } from '@/utils/fulfillment';
import { ORDER_ASAP } from '@/utils/locations';
import { safelyGetString } from '@/utils/stringHelpers';

interface Props {
  checkDeliveryAddress: (address: DeliveryAddress, shouldAutoRedirect?: boolean) => void;
  loading: boolean;
  theme: WebConfig;
  formValues?: Record<string, string>;
}

export const formName = 'delivery-form';
export const formSubmitButtonTestId = 'delivery_form_submit';

/**
 * This form allows users select their address and desired delivery time window.
 *
 * - If they are logged out, they can enter an address via either a Google
 *   autocomplete or a full delivery form. (address, state, zip, etc.)
 * - If they are logged in, they can select from a list of saved addresses.
 */
const _DeliveryForm = ({
  myAddresses,
  fetchAddresses,
  theme,
  loading,
  fulfillmentModal,
  checkDeliveryAddress,
  formValues,
  handleSubmit,
  change,
  strings,
  reset,
  googleAddressNotSelected,
  setDeliveryAddressContinue,
  clearDeliverySearchErrors,
  initialValues,
}: InjectedFormProps<DeliveryAddress, Props> & Props & ReduxProps) => {
  const [isStoredAddressSelected, setIsStoredAddressSelected] = useState(false);
  const { timeslotOverride, addressValidity, errorMessage, location } = fulfillmentModal;

  // Web Configs
  const deliverySearchEnabled = theme.locations?.delivery_search;
  const displayStoredAddresses = theme.accounts?.stored_addresses && !!myAddresses?.length;

  const determineFormStatus = showManualDeliveryFormConditions(
    displayStoredAddresses,
    isStoredAddressSelected,
    formValues,
  );

  const defaultErrorMessage =
    'Delivery to this address is not currently available. Please try another address.';

  // is the manual delivery form be shown as well as stored addresses?
  const [showDeliveryForm, setShowDeliveryForm] = useState(determineFormStatus);

  /**
   * If a timeslot override exists, manually set the time_wanted
   * value since the day/time toggle won't be shown.
   *
   * @TODO see if this ever changes. If not, we can make it only fire on mount.
   */
  useEffect(() => {
    if (timeslotOverride) {
      change('time_wanted', timeslotOverride);
    }
  }, [timeslotOverride]);

  // If a user is logged in, fetch their stored addresses on mount.
  useEffect(() => {
    if (userLoggedIn()) {
      fetchAddresses();
    }

    // NOTE: we have to set formValues time_wanted to initial value if it exists when basket is created
    if (initialValues?.time_wanted) {
      change('time_wanted', initialValues.time_wanted);
    }
  }, []);

  /**
   * The Continue button is disabled under the following conditions:
   * - Delivery search is enabled for the brand, but...
   * - The given address is not valid
   * - Google doesn't recognize the given address
   *
   * @TODO rename `googleAddressNotSelected` in the store since it's ambiguous.
   */
  const isContinueButtonDisabled =
    deliverySearchEnabled && (!addressValidity || googleAddressNotSelected);

  // Check manual form conditions again when displayStoredAddresses changes
  useEffect(() => {
    setShowDeliveryForm(determineFormStatus);
  }, [displayStoredAddresses]);

  // Any time the form values change, validate and set the delivery info.
  useEffect(() => {
    // Clear any validation errors before checking the address.
    clearDeliverySearchErrors();
    /**
     * Validate the address if these conditions are met:
     * - The brand has delivery search enabled OR the user has selected a stored
     *   address. If not, their address will be validated when they click Continue.
     * - A street address has been entered.
     */
    if ((deliverySearchEnabled || isStoredAddressSelected) && formValues?.street_address) {
      // @ts-expect-error redux-form values are typed as Record<string, string>
      checkDeliveryAddress(formValues);
    }
  }, [formValues]);

  // Show the manual delivery form.
  const showManualDeliveryForm = () => {
    // Reset the form to its empty state.
    reset();
    // The user is no longer using a stored address.
    setIsStoredAddressSelected(false);
    // ...and show the manual delivery form.
    setShowDeliveryForm(true);
  };

  // Submits the delivery form.
  const submit = () => {
    /**
     * If delivery search is enabled, the address has already been validated.
     * Clicking Continue will just close the modal and handle redirect logic.
     */
    if (deliverySearchEnabled || isStoredAddressSelected) {
      setDeliveryAddressContinue();
    } else {
      /**
       * If delivery search is *not* enabled, the address must be validated when
       * Continue is clicked. The second argument (`shouldAutoRedirect`) will
       * redirect the user to the correct store page once validation completes.
       */
      // @ts-expect-error redux-form values are typed as Record<string, string>
      checkDeliveryAddress(formValues, true);
    }

    if (formValues?.time_wanted?.toLowerCase() !== ORDER_ASAP.toLowerCase()) {
      genericEventHandler(GlobalEvents.WANTED_AT_SELECTED, {
        name: CONVEYANCE_TYPES.DELIVERY.toLowerCase(),
        details: formValues?.time_wanted,
      });
    }
  };

  // Update the desired delivery date & time.
  const updateDeliveryTime = (timestamp: string) => {
    change('time_wanted', timestamp);
  };

  // Update the selected address.
  const updateAddress = (address: DeliveryAddress) => {
    change('id', address.id);
    change('street_address', address.street_address);
    change('street_address_2', address.street_address_2);
    change('city', address.city);
    // If stored address, null out the state
    change('state', address.state ?? null);
    change('zip_code', address.zip_code);
  };

  return (
    <StyledForm onSubmit={handleSubmit(submit)}>
      <StringAccessor tag="h2" accessor="delivery.modal_header" html={true} />
      {/* Address invalid */}
      {addressValidity === false && (
        <p style={{ color: '#ff2d44' }}>{`Sorry! ${errorMessage ?? defaultErrorMessage}`}</p>
      )}
      {displayStoredAddresses && (
        <div>
          <StringAccessor
            tag={StyledDeliveryFormSubheader}
            accessor="account.addresses_header"
            html={true}
          />

          <StyledRadioLegend>My Addresses</StyledRadioLegend>
          {/* Stored Addresses */}
          {myAddresses.map((storedAddress, index) => (
            <Fragment key={storedAddress.id}>
              <StyledRadioRow mobilePadding={20}>
                <RadioOption
                  id={`address-line-${index}`}
                  checked={Boolean(formValues?.id && Number(formValues.id) === storedAddress.id)}
                  name="stored-address"
                  onChange={() => {
                    // The user has clicked a stored address.
                    setIsStoredAddressSelected(true);
                    // Hide the manual delivery form.
                    setShowDeliveryForm(false);
                    // Update the form with the selected address.
                    updateAddress(storedAddress);
                  }}
                >
                  <p>
                    {/* Address details */}
                    {storedAddress.street_address}
                    {storedAddress.street_address_2 && <>,&nbsp;{storedAddress.street_address_2}</>}
                    ,&nbsp;{storedAddress.city}, {storedAddress?.zip_code}
                  </p>
                </RadioOption>
              </StyledRadioRow>
            </Fragment>
          ))}

          {/* Button to Show Manual Form */}
          {!showDeliveryForm && (
            <StyledAddAddressButton type="button" onClick={showManualDeliveryForm}>
              <StringAccessor accessor="delivery.add_new_address_cta" html={true} />
            </StyledAddAddressButton>
          )}
        </div>
      )}

      {/* Timeslot Dropdown */}
      {!timeslotOverride && (
        <>
          <StringAccessor
            tag={StyledDeliveryFormSubheader}
            accessor="handoff_time.order_info_header"
            html={true}
            dataObj={{ fulfillmentType: CONVEYANCE_TYPES.DELIVERY }}
          />
          <Render condition={Boolean(location && location?.id)}>
            <HandoffTimePicker
              onChange={updateDeliveryTime}
              value={formValues?.time_wanted}
              isSubmitting={loading}
              location={location!}
              handoffType={CONVEYANCE_TYPES.DELIVERY}
              supportsAsap={false}
            />
          </Render>
        </>
      )}

      {/* Full Delivery Form */}
      {showDeliveryForm && (
        <>
          <StringAccessor
            tag={StyledDeliveryFormSubheader}
            accessor="delivery.address_details_subheader"
            html={true}
          />
          {deliverySearchEnabled ? (
            <GoogleAutocomplete
              loading={loading}
              apiKey={theme.locations.map_key}
              checkOrSearchDeliveryCoverage={updateAddress}
              formValues={formValues}
            />
          ) : (
            <>
              <Field
                name="street_address"
                type="text"
                placeholder="Address"
                component={ReduxFormField}
              />
              <Field
                name="street_address_2"
                type="text"
                placeholder={safelyGetString(
                  strings,
                  'checkout_delivery_details.apartment_number_input_placeholder',
                )}
                component={ReduxFormField}
              />
              <StyledCityStateZip>
                <Field name="city" type="text" placeholder="City" component={ReduxFormField} />

                <div>
                  <Field
                    name="state"
                    type="text"
                    placeholder="State"
                    component={ReduxFormSelect}
                    // @ts-expect-error type `e`.
                    handleChange={(e) => change('state', e.target.value)}
                    optionsArray={Object.keys(stateAbbrMappings)}
                    title="State"
                    titleEnabled={true}
                    defaultValue={formValues?.state || ''}
                    {...{ width: '80px' }}
                  />
                </div>
                <div>
                  <Field
                    name="zip_code"
                    type="text"
                    maxLength={6}
                    inputmode="numeric"
                    placeholder="Zip Code"
                    component={ReduxFormField}
                  />
                </div>
              </StyledCityStateZip>
            </>
          )}
        </>
      )}
      <StyledContinueButton
        data-testid={formSubmitButtonTestId}
        disabled={isContinueButtonDisabled}
        type="submit"
        $submitting={loading}
      >
        <StringAccessor accessor="delivery.modal_submit_cta" />
      </StyledContinueButton>
    </StyledForm>
  );
};

const mapStateToProps = (state: RootState) => ({
  formValues: getFormValues('delivery-form')(state),
  myAddresses: state.app.me.myAddresses,
  strings: state.app.cmsConfig.strings,
  fulfillmentModal: state.app.global.fulfillmentModal,
  googleAddressNotSelected: state.app.locations.googleAddressNotSelected,
});

const mapDispatchToProps = {
  clearDeliverySearchErrors: locationsActions.clearDeliverySearchErrors,
  fetchAddresses: meActions.fetchAddresses,
  setDeliveryAddressContinue: conveyanceModeActions.setDeliveryAddressContinue,
  toggleFulfillmentModal: globalActions.toggleFulfillmentModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const DeliveryForm = compose<any>(
  withTheme,
  reduxForm<DeliveryAddress, ReduxProps>({
    form: formName,
    validate,
  }),
  connector,
)(_DeliveryForm);
