import styled from 'styled-components';
import { LAYOUT } from '@/constants/styles';

export const StyledFormContainer = styled.div`
  padding: ${LAYOUT.GUTTER * 2}px;

  @media (max-width: 767px) {
    padding: 0px;
  }
`;

export const StyledFormCTA = styled.div`
  margin: ${LAYOUT.GUTTER}px 0 0;

  a {
    text-decoration: underline;
  }
`;
