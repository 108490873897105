import styled from 'styled-components';

export const StyledItemRecipient = styled.p`
  display: flex;
  align-items: center;

  svg {
    margin-left: -3px;
  }

  span {
    font-style: normal;
    margin-left: 3px;
  }
`;
