import styled from 'styled-components';

export const Button = styled.button`
  align-items: center;
  appearance: none;
  background: white;
  border: none;
  border-radius: 100%;
  box-shadow: 0 0 0.5rem 0 rgba(26, 26, 26, 0.1);
  color: var(--config-global__primary-active-color);
  display: flex;
  height: var(--size-12);
  justify-content: center;
  position: fixed;
  left: var(--size-4);
  top: var(--size-6);
  width: var(--size-12);
  z-index: var(--layer-100);

  svg {
    flex: 0 0 var(--size-6);
  }
`;

export const Drawer = styled.div`
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: var(--layer-50);
`;

interface OverlayProps {
  $isVisible: boolean;
}

export const Overlay = styled.div<OverlayProps>`
  ${({ $isVisible }) => `
    --android-navigation--overlay__opacity: ${$isVisible ? 1 : 0};
  `}

  background-color: rgba(26, 26, 26, 0.2);
  backdrop-filter: blur(13.6px);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: var(--android-navigation--overlay__opacity);
  position: absolute;
  transition: all 0.4s ease;
`;

interface PanelProps {
  $isVisible: boolean;
}

export const Panel = styled.div<PanelProps>`
  ${({ theme, $isVisible }) => `
    --android-navigation--panel__background-color: ${theme.android_drawer.background_color};
    --android-navigation--panel__translateX: ${$isVisible ? '0' : '-100%'};
    --android-navigation--panel__transition-timing-function: ${$isVisible ? 'ease' : 'ease-out'};
    --android-navigation--panel__transition-duration: ${$isVisible ? '.4s' : '.2s'};
  `}

  background-color: var(--android-navigation--panel__background-color, white);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  transition: all var(--android-navigation--panel__transition-duration)
    var(--android-navigation--panel__transition-timing-function);
  transform: translateX(var(--android-navigation--panel__translateX));
  max-width: var(--size-80);
  width: 75%;
`;

export const ScrollContainer = styled.div`
  ${({ theme }) => `
    --android-navigation-scroll-container__border-color: ${theme.android_drawer.separator};
  `}

  border-top: 1px solid var(--android-navigation-scroll-container__border-color, transparent);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
`;
