import { useRouter } from 'next/router';

interface Props {
  component: JSX.Element;
  allowedPages: Record<string, AllowedPageProps | null>;
}

// Optional Params
interface AllowedPageProps {
  exactMatch?: boolean;
  hideOnMobile?: boolean;
}

/*
This component handles logic for whether to display route-specific components - also accounts for subpages (i.e. `/account/rewards`) and whether component should be displayed on mobile
*/
export const GenericRouteAccessor = ({ allowedPages, component }: Props) => {
  const router = useRouter();
  const currentRoute = router?.route;

  if (!currentRoute) {
    return null;
  }

  // First part of the URL - i.e. `/account`
  const mainRoute = currentRoute && `/${currentRoute.split('/')[1]}`;

  // Check if current route OR main route is in our allowedPages (if exactMatch is false)
  const allowedOnRoute =
    containsRoute(allowedPages, currentRoute) ||
    (containsRoute(allowedPages, mainRoute) && !allowedPages[mainRoute]?.exactMatch);

  // Check screen size according to tabletMax breakpoint
  const mobileSize = typeof window !== 'undefined' && window.innerWidth <= 767;

  // If component fails our route check OR the component is supposed to be hidden on mobile, return null
  if (!allowedOnRoute || (mobileSize && allowedPages[currentRoute]?.hideOnMobile)) {
    return null;
  }

  // Else, return the component
  return component;
};

const containsRoute = (allowedPages: Record<string, AllowedPageProps | null>, route: string) => {
  return Object.keys(allowedPages).includes(route);
};

export function getAllowedRoutes(route: string, allowed: string[]) {
  return allowed.includes(route);
}
