export interface IconProps {
  title: string;
}

export enum SocialLinks {
  facebook = 'facebook',
  instagram = 'instagram',
  tiktok = 'tiktok',
  twitter = 'twitter',
  youtube = 'youtube',
}
