import { type DeliveryAddress } from '@koala/sdk';
import { REGEX } from '@/constants/validation';

export const addressValidation = (values: DeliveryAddress) => {
  const errors: any = {};

  // Street
  if (!values.street_address) {
    errors.street_address = 'Required';
  }

  // City
  if (!values.city) {
    errors.city = 'Required';
  }

  // State
  if (!values.state) {
    errors.state = 'Required';
  }

  // Zip Code
  if (!values.zip_code) {
    errors.zip_code = 'Required';
  }
  if (values.zip_code && !REGEX.ZIP_CODE_USA_AND_POSTAL_CODE_CANADA.test(values.zip_code)) {
    errors.zip_code = 'Invalid';
  }

  // Time wanted mode
  if (!values.time_wanted) {
    errors.time_wanted = 'Required';
  }

  return errors;
};
