import { CONVEYANCE_TYPES } from '@koala/sdk/v4';
import { useRouter } from 'next/router';
import { type ReactNode } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { CaretIcon } from './icons/caret';
import { Box, Stack } from '@/components/ui';
import { LoadingPlaceholder } from '@/components/ui/loading/placeholder';
import { ROUTES } from '@/constants/routes';
import { useLocation } from '@/features/locations/service';
import { useSelector } from '@/redux';
import { selectConveyance } from '@/redux/conveyanceMode/reducer';
import { getLocationId } from '@/utils/locations';

export function Destination() {
  const { detail } = useSelector((state) => state.app.locations);
  const { location, type, address } = useSelector(selectConveyance);
  const { data, isSuccess } = useLocation(location);
  const { webConfig } = useSelector((state) => state.app.cmsConfig);
  const router = useRouter();
  const href = data?.data
    ? `/store/${getLocationId(data?.data, webConfig)}/${data?.data?.label}`
    : '';

  if (!isSuccess) {
    return null;
  }

  let origin = 'from';
  let label: string | ReactNode =
    location !== detail?.id || !router.pathname.includes(ROUTES.STORE) ? (
      <Link href={href} legacyBehavior>
        {data.data.label}
      </Link>
    ) : (
      data.data.label
    );
  let loading = false;

  if (type === CONVEYANCE_TYPES.DELIVERY) {
    origin = 'to';
    if (address?.street_address) {
      let streetAddress = address.street_address;

      if (address.street_address_2) {
        streetAddress = `${streetAddress} ${address.street_address_2}`;
      }
      label = `${streetAddress}, ${address.city}`;
    } else {
      loading = true;
      label = '';
    }
  }

  return (
    <Stack
      gap="$1-5"
      css={{
        overflow: 'hidden',
      }}
    >
      <CaretIcon />

      <Stack
        css={{
          flex: '1 1 auto',
          overflow: 'hidden',
          minWidth: '100px',
        }}
      >
        <Origin>{origin}</Origin>

        {loading ? (
          <Box
            css={{
              borderRadius: '$md',
              height: '$8',
              overflow: 'hidden',
              position: 'relative',
              width: '$24',
            }}
          >
            <LoadingPlaceholder />
          </Box>
        ) : (
          <Label>{label}</Label>
        )}
      </Stack>

      {!loading && <CaretIcon />}
    </Stack>
  );
}

const Origin = styled.span({
  opacity: 0.5,
});

const Label = styled.span`
  min-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  a:link {
    color: ${(props) => props.theme.header.font.color};
    background-color: transparent;
    text-decoration: none;
  }
  a:visited {
    color: ${(props) => props.theme.header.font.color};
    background-color: transparent;
    text-decoration: none;
  }
  a:hover {
    color: ${(props) => props.theme.header.font.color};
    background-color: transparent;
    text-decoration: underline;
  }
  a:active {
    color: ${(props) => props.theme.header.font.color};
    background-color: transparent;
    text-decoration: underline;
  }
`;
