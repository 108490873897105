import { readableColor } from 'polished';
import styled from 'styled-components';
import { LinkHoverCss } from '@/components/uielements/css';
import { COLORS, LAYOUT, ORDER } from '@/constants/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { safePolished } from '@/utils/safePolished';

export const StyledDropdownOverlay = styled.div<{ $isVisible: boolean }>`
  background-color: ${COLORS.SEMI_TRANSPARENT};
  left: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${ORDER.ACCOUNT_DROPDOWN};
  visibility: ${(props) => (props.$isVisible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  transition: all 0.2s linear;
`;

interface DropdownModalContainer {
  $isVisible: boolean;
}

export const StyledDropdownModalContainer = styled.nav<DropdownModalContainer>(
  ({ theme, $isVisible }) => ({
    background: theme.modals.background_color ?? 'white',
    overflowY: 'scroll',
    maxHeight: '100%',
    width: '320px',
    position: 'fixed',
    top: '0px',
    right: '20px',
    transform: `translateY(${$isVisible ? 0 : -150}px)`,
    transition: 'all 0.2s linear',
    ...getFontStyles(theme.modals.font),

    '@media (max-width: 767px)': {
      right: 0,
      width: '100%',
    },
  }),
);

export const StyledAccountDropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  ul {
    padding: 0;
    margin: 0;
  }
`;

export const StyledDropdownHeaderContent = styled.div`
  padding: ${LAYOUT.GUTTER}px;

  h3 {
    font-size: 20px;
    font-family: ${({ theme }) => theme.primary_font.bold.font_family};
    margin: 0;
  }

  p {
    margin: ${LAYOUT.GUTTER / 4}px 0 0;
  }
`;

export const StyledDropdownButton = styled.a`
  ${({ theme }) => `
    border: none;
    border-top: 1px solid ${theme.modals.border_color ?? ''};
    ${theme.modals.active_font_color ? `color: ${theme.modals.active_font_color}` : ''};
  `}

  width: 100%;
  height: 72px;
  font-size: 16px;
  cursor: pointer;
  background: none;
  font-family: ${({ theme }) => theme.primary_font.bold.font_family};
  text-align: left;
  padding: ${LAYOUT.GUTTER}px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${LinkHoverCss}
  text-decoration: none;
`;

/** @FIXME font refactor (what does readableColor do when the value is "inherit", etc). */
export const StyledAccountDropdownNumber = styled.span`
  ${({ theme }) => {
    const { color } = getFontStyles(theme.modals.font, ['color']);
    return `
    background: ${color ?? 'inherit'}
    color: ${safePolished(readableColor)(color)};
  `;
  }}
  borderRadius: 50%;
  fontSize: 14px;
  height: 32px;
  objectFit: contain;
  padding: 6px;
  textAlign: center;
  width: 32px;

  @media (max-width: 767px): {
    minWidth: 32px;
    minHeight: 32px;
    fontFamily: ${({ theme }) => theme.modals.font.font_family};
  },
`;
