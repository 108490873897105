import styled from 'styled-components';

export const Button = styled.button(({ theme }) => ({
  appearance: 'none',
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: '1px solid',
  borderColor: theme.buttons.primary_button_border_color,
  borderRadius: '0.5rem',
  color: 'inherit',
  display: 'flex',
  height: '2rem',
  gap: '0.25rem',
  padding: '0 0.5rem',
  whiteSpace: 'nowrap',
}));
