import { getMenu } from '@koala/sdk/v4';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { DELIVERY_TIME_WANTED_MODES } from '@/constants/global';
import { createHttpClient } from '@/services/client';
import { __DANGEROUS_EMPTY_REQUEST_ORIGIN, getOrigin } from '@/utils';
import { objectifyOptions } from '@/utils/events';

interface MenuService {
  id: number | undefined;
  ifModifiedSince?: string;
  wantedAt?: string;
}
export function useMenu({ id, ifModifiedSince, wantedAt }: MenuService) {
  let origin: string;

  if (typeof window !== 'undefined') {
    origin = getOrigin(window.location.host);
  } else {
    origin = __DANGEROUS_EMPTY_REQUEST_ORIGIN;
  }

  const client = createHttpClient({ origin });
  const { data, status, fetchStatus } = useQuery({
    queryKey: [
      'menu',
      {
        id,
        ifModifiedSince,
        wantedAt: wantedAt ?? DELIVERY_TIME_WANTED_MODES.ASAP,
      },
    ],
    queryFn: () =>
      getMenu(
        {
          id: id ?? 0,
          ifModifiedSince,
          wantedAt: wantedAt === DELIVERY_TIME_WANTED_MODES.ASAP ? undefined : wantedAt,
          compressed: true,
        },
        { client },
      ),
    enabled: id !== undefined,
    refetchOnWindowFocus: false,
    staleTime: wantedAt === DELIVERY_TIME_WANTED_MODES.ASAP ? 120000 : 300000,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (data?.[0]) {
      objectifyOptions(data[0]);
    }
  }, [data]);

  return {
    categories: data?.[0] ? data[0] : [],
    status,
    isLoading: fetchStatus === 'fetching',
  };
}
