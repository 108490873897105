import styled, { type CSSObject } from 'styled-components';

interface ElementProps {
  $styles: CSSObject;
}

export const Element = styled.button<ElementProps>`
  appearance: none;
  background: none;
  border: none;
  color: var(--config-global__primary-active-color);
  cursor: pointer;
  font-family: var(--config-primary-font-family__bold);
  font-size: 14px;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }

  ${({ $styles }) => $styles}
`;
