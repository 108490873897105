import { sdkUtils, type MenuProductOption, type MenuProductOptionGroup } from '@koala/sdk';
import {
  StyledChangeQuantityButton,
  StyledOptionCard,
  StyledOptionContainer,
  StyledQuantitySelector,
} from '../styles';
import { Option } from './option';
import { useDispatch } from '@/redux';
import customizeActions from '@/redux/customize/actions';
import { doesGroupHaveImages } from '@/utils/menu';

interface Props {
  group: MenuProductOptionGroup;
  option: MenuProductOption;
}

export function OptionCard({ group, option }: Props) {
  const dispatch = useDispatch();

  const groupHasImages = doesGroupHaveImages(group);
  const isOptionDisabled =
    group.is_max_aggregate_quantity_reached || option.is_max_selections_reached;
  const isOptionIncrementDisabled =
    /** @TODO ensure that `total_aggregate_quantity` and `max_aggregate_quantity` are defined. */
    isOptionDisabled ||
    // @ts-expect-error
    group.total_aggregate_quantity + group.choice_quantity_increment >
      // @ts-expect-error
      group.max_aggregate_quantity;

  // An option is selected or completely deselected by clicking on it.
  function toggleSelectedOption() {
    option.selected
      ? dispatch(customizeActions.decrementOption(option.id, true))
      : dispatch(customizeActions.incrementOption(option.id));
  }

  return (
    <StyledOptionContainer
      data-testid="option-container"
      selected={!!option.selected}
      $is_inverted={!!option.is_inverted}
      className={`koala__ui-item-option${groupHasImages ? '-image' : ''}`}
      data-global-id={option.global_option_id}
      $groupHasImages={groupHasImages}
    >
      <StyledOptionCard
        onClick={toggleSelectedOption}
        // If we're at our max and option is not currently selected, disable selection
        // Do not disable if maxAgg is 0 - this is Olo's single product mode
        disabled={isOptionDisabled}
        $groupHasImages={groupHasImages}
        aria-label={`${option.selected ? 'Deselect' : 'Select'} ${option.name}`}
        aria-pressed={option.selected}
      >
        <Option option={option} supportsQuantitySelection={group.supports_quantity_selection} />
      </StyledOptionCard>
      {group.supports_quantity_selection && (
        <StyledQuantitySelector selected={!!option.selected}>
          <StyledChangeQuantityButton
            onClick={() => dispatch(customizeActions.decrementOption(option.id))}
            disabled={option.quantity === 0}
            increment={false}
            aria-label={`Decrement ${option.name} quantity by ${
              group.choice_quantity_increment || 1
            }`}
          >
            -
          </StyledChangeQuantityButton>
          <div>{option.quantity}</div>
          <StyledChangeQuantityButton
            onClick={() => dispatch(customizeActions.incrementOption(option.id))}
            // Disable if we're already at maxAgg, or if option quantity is max_choice_quantity or 99
            disabled={isOptionIncrementDisabled}
            increment={true}
            aria-label={`Increment ${option.name} quantity by ${sdkUtils.calculateIncrementor(
              option,
              group,
            )}`}
          >
            +
          </StyledChangeQuantityButton>
        </StyledQuantitySelector>
      )}
    </StyledOptionContainer>
  );
}
