import { type CSSObject } from 'styled-components';
import { Container } from './box.styles';

interface Props {
  children: React.ReactNode;
  overrides?: CSSObject;
}

export const Box = ({
  children,
  overrides = {},
  ...otherProps
}: Props & React.HTMLProps<HTMLDivElement>) => (
  <Container $styles={overrides} {...otherProps}>
    {children}
  </Container>
);
