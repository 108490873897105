import { type WebConfig } from '@koala/sdk';
import { memo } from 'react';
import { withTheme } from 'styled-components';
import { StyledChevronIcon, StyledStarIcon, CustomAccountIcon } from './styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';

/**
 * Connected UI Elements
 *
 */
interface ThemeProps {
  theme: WebConfig;
}

// Heart Icon
const _StaticHeartIcon = memo(({ theme }: ThemeProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g fill={theme.global.primary_active_color}>
        <path d="M11.995 21c.185 0 .441-.11.636-.221C18.265 17.199 22 13.086 22 8.893 22 5.453 19.578 3 16.346 3c-1.858 0-3.469.935-4.351 2.383C11.123 3.945 9.5 3 7.644 3 4.41 3 2 5.454 2 8.893c0 4.193 3.735 8.306 9.369 11.886.185.11.441.221.626.221zm0-1.297c-.041 0-.092-.04-.175-.09-4.535-2.967-8.558-6.95-8.558-10.72 0-2.766 1.858-4.646 4.372-4.646 1.908 0 3.088 1.156 3.796 2.313.216.332.36.432.565.432.205 0 .339-.11.575-.432.728-1.147 1.888-2.313 3.786-2.313 2.514 0 4.372 1.88 4.372 4.646 0 3.77-4.013 7.753-8.548 10.72-.083.05-.144.09-.185.09z" />
      </g>
    </g>
  </svg>
));
export const ConnectedStaticHeartIcon = withTheme(_StaticHeartIcon);

const _SelectedHeartIcon = memo(({ theme }: ThemeProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g fill={theme.global.primary_active_color}>
        <path d="M11.995 21c.185 0 .441-.11.636-.221C18.265 17.199 22 13.086 22 8.893 22 5.453 19.578 3 16.418 3c-1.93 0-3.54 1.066-4.423 2.715C11.123 4.076 9.5 3 7.582 3 4.412 3 2 5.454 2 8.893c0 4.193 3.735 8.306 9.369 11.886.185.11.441.221.626.221z" />
      </g>
    </g>
  </svg>
));
export const ConnectedSelectedHeartIcon = withTheme(_SelectedHeartIcon);

// Person Icon
// eslint-disable-next-line react/display-name
const _PersonIcon = memo(({ theme }: ThemeProps) =>
  theme.header.account_icon ? (
    <CustomAccountIcon src={theme.header.account_icon} role="presentation" alt="Account Icon" />
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24">
      <path
        fill={theme.header.active_background_color ?? 'black'}
        fillRule="evenodd"
        d="M12.008 12.305c1.687 0 3.094-1.492 3.094-3.43 0-1.875-1.415-3.336-3.094-3.336-1.672 0-3.094 1.477-3.086 3.344 0 1.93 1.398 3.422 3.086 3.422zm5.031 7.156c1.063 0 1.5-.305 1.5-.984 0-2.008-2.508-4.774-6.539-4.774s-6.54 2.766-6.54 4.774c0 .68.438.984 1.5.984h10.08z"
      />
    </svg>
  ),
);
export const ConnectedPersonIcon = withTheme(_PersonIcon);

// Cart Icon
// eslint-disable-next-line react/display-name
const _CartIcon = memo(({ theme }: ThemeProps) =>
  theme.header.cart_icon ? (
    <img src={theme.header.cart_icon} role="presentation" alt="Cart Icon" />
  ) : (
    <svg
      width="14px"
      height="17px"
      viewBox="0 0 14 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Cart Icon</title>
      <defs></defs>
      <g id="S02.-Menu" stroke="none" strokeWidth="1" fillRule="evenodd">
        <path
          fill={theme.header.active_font_color ?? 'black'}
          d="M3.25,3.75 C3.25,1.67893219 4.92893219,0 7,0 C9.07106781,0 10.75,1.67893219 10.75,3.75 L13,3.75 C13.5522847,3.75 14,4.19771525 14,4.75 L14,15.75 C14,16.3022847 13.5522847,16.75 13,16.75 L1,16.75 C0.44771525,16.75 6.76353751e-17,16.3022847 0,15.75 L0,4.75 C-6.76353751e-17,4.19771525 0.44771525,3.75 1,3.75 L3.25,3.75 Z M4.75,3.75 L9.25,3.75 C9.25,2.50735931 8.24264069,1.5 7,1.5 C5.75735931,1.5 4.75,2.50735931 4.75,3.75 Z"
        />
      </g>
    </svg>
  ),
);
export const ConnectedCartIcon = withTheme(_CartIcon);

// Chevron Icon
const _ChevronIcon = memo(function Value({ theme }: ThemeProps) {
  const { color } = getFontStyles(theme.header.font, ['color']);

  return (
    <StyledChevronIcon>
      <svg width="12" height="12" viewBox="0 0 12 12">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0L12 0 12 12 0 12z" transform="matrix(0 1 1 0 0 0)" />
          <path
            fill={color ?? 'inherit'}
            d="M6.293 8.372l3.6-4.095c.147-.179.142-.458-.01-.632-.153-.173-.399-.178-.557-.01L6.005 7.406 2.683 3.634C2.608 3.548 2.507 3.5 2.4 3.5c-.106 0-.208.048-.283.134-.156.178-.156.466 0 .643l3.62 4.095c.155.17.402.17.556 0z"
          />
        </g>
      </svg>
    </StyledChevronIcon>
  );
});

export const ConnectedChevronIcon = withTheme(_ChevronIcon);

// Rewards Icon
// eslint-disable-next-line react/display-name
const _RewardsIcon = memo(({ theme }: ThemeProps) =>
  theme.accounts.rewards_icon ? (
    // eslint-disable-next-line @next/next/no-img-element
    <img src={theme.accounts.rewards_icon} role="presentation" alt="Rewards Icon" />
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
      <g fill="none" fillRule="evenodd">
        <path
          fill={theme.global.primary_active_color}
          d="M50.36 80c-2.106 0-4.175-.964-6.055-1.84-1.538-.717-3.128-1.458-4.305-1.458-1.177 0-2.767.74-4.305 1.458-2.474 1.152-5.277 2.458-8.058 1.517-2.886-.977-4.442-3.819-5.815-6.325-.806-1.473-1.64-2.995-2.512-3.657-.894-.678-2.558-1.042-4.167-1.393-2.69-.589-5.74-1.255-7.504-3.781-1.747-2.504-1.42-5.718-1.13-8.554.175-1.72.357-3.497.006-4.622-.321-1.031-1.434-2.308-2.51-3.541C2.126 45.654 0 43.216 0 40s2.127-5.653 4.004-7.804c1.077-1.233 2.19-2.509 2.511-3.54.35-1.125.17-2.904-.006-4.623-.289-2.836-.617-6.05 1.13-8.553 1.764-2.526 4.813-3.193 7.504-3.781 1.61-.352 3.274-.716 4.167-1.394.872-.661 1.706-2.184 2.512-3.656 1.373-2.507 2.93-5.349 5.815-6.326 2.781-.942 5.584.364 8.058 1.517 1.538.717 3.128 1.458 4.305 1.458 1.177 0 2.767-.741 4.306-1.457C46.78.688 49.582-.618 52.363.323c2.886.977 4.442 3.819 5.815 6.326.807 1.472 1.64 2.995 2.512 3.656.894.678 2.558 1.042 4.167 1.394 2.691.588 5.741 1.255 7.504 3.782 1.747 2.503 1.42 5.716 1.13 8.552-.175 1.72-.357 3.498-.006 4.622.321 1.033 1.434 2.308 2.511 3.542C77.873 34.347 80 36.785 80 40c0 3.216-2.127 5.654-4.005 7.804-1.076 1.233-2.19 2.51-2.51 3.541-.35 1.124-.17 2.902.006 4.621.289 2.837.617 6.051-1.13 8.555-1.764 2.526-4.814 3.192-7.505 3.78-1.609.352-3.273.716-4.166 1.394-.872.662-1.705 2.184-2.512 3.656-1.373 2.507-2.929 5.349-5.816 6.326-.647.217-1.323.326-2.003.323z"
        />
        <path
          fill="#FFF"
          d="M47.98 54.667c2.448 0 3.83-1.336 3.83-3.776v-9.764c1.398-.115 2.19-1.091 2.19-2.556v-3.144c0-1.565-.893-2.57-2.463-2.57h-4.004c1.037-.79 1.656-1.895 1.656-3.13 0-2.585-2.002-4.394-4.638-4.394-2.16 0-3.845 1.235-4.551 3.418-.706-2.183-2.39-3.418-4.551-3.418-2.636 0-4.638 1.81-4.638 4.394 0 1.235.59 2.34 1.627 3.13h-3.975c-1.512 0-2.463 1.005-2.463 2.57v3.144c0 1.465.792 2.441 2.19 2.556v9.764c0 2.44 1.382 3.776 3.83 3.776h15.96zm-9.031-21.81h-2.002c-2.622 0-3.961-1.58-3.961-3.13 0-1.436 1.123-2.34 2.563-2.34 1.974 0 3.4 1.593 3.4 4.235v1.235zm4.104 0h-2.002v-1.235c0-2.642 1.426-4.235 3.4-4.235 1.44 0 2.563.904 2.563 2.34 0 1.55-1.426 3.13-3.96 3.13zm-4.205 6.605h-9.996c-.735 0-1.066-.374-1.066-1.106v-2.728c0-.732.331-1.091 1.066-1.091h9.996v4.925zm12.315 0h-10.01v-4.925h10.01c.734 0 1.051.359 1.051 1.091v2.728c0 .732-.317 1.106-1.051 1.106zM38.848 53.016H32.02c-1.326 0-2.046-.733-2.046-2.054v-9.82h8.873v11.874zm9.131 0h-6.827V41.14h8.873v9.821c0 1.321-.72 2.054-2.046 2.054z"
        />
      </g>
    </svg>
  ),
);

export const ConnectedRewardsIcon = withTheme(_RewardsIcon);

// Star Icon
const _StarIcon = memo(({ theme }: ThemeProps) => (
  <StyledStarIcon>
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Star</title>
      <path
        d="M4.00439 16.7617C4.32471 17.0117 4.73096 16.9258 5.21533 16.5742L9.34814 13.5352L13.4888 16.5742C13.9731 16.9258 14.3716 17.0117 14.6997 16.7617C15.02 16.5195 15.0903 16.1211 14.895 15.5508L13.2622 10.6914L17.4341 7.69141C17.9185 7.34766 18.1138 6.98828 17.9888 6.59766C17.8638 6.22266 17.4966 6.03516 16.895 6.04297L11.7778 6.07422L10.2231 1.19141C10.0356 0.613281 9.75439 0.324219 9.34814 0.324219C8.94971 0.324219 8.66846 0.613281 8.48096 1.19141L6.92627 6.07422L1.80908 6.04297C1.20752 6.03516 0.840332 6.22266 0.715332 6.59766C0.58252 6.98828 0.785645 7.34766 1.27002 7.69141L5.44189 10.6914L3.80908 15.5508C3.61377 16.1211 3.68408 16.5195 4.00439 16.7617Z"
        fill={theme.global.primary_active_color}
      />
    </svg>
  </StyledStarIcon>
));
export const ConnectedStarIcon = withTheme(_StarIcon);
