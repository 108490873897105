import { type SVGProps } from 'react';
import { type IconProps } from './types';

export const Youtube = ({ title, ...otherProps }: IconProps & SVGProps<SVGSVGElement>) => (
  <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title>{title}</title>

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.14546 16.9078H16.8541C18.1532 16.9078 19.205 15.7647 19.1998 14.3459V9.76191C19.1998 8.34881 18.1532 7.19995 16.8541 7.19995H7.14546C5.85167 7.19995 4.7998 8.34307 4.7998 9.76191V14.3459C4.7998 15.759 5.84641 16.9078 7.14546 16.9078ZM10.4629 9.62695L14.5471 11.9821L10.4629 14.3373V9.62695Z"
      fill="currentColor"
    />
  </svg>
);
