import { readableColor } from 'polished';
import styled from 'styled-components';
import { LAYOUT } from '@/constants/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { safePolished } from '@/utils/safePolished';

export const StyledHeaderAccountButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
`;

export const StyledFirstName = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`;

export const StyledNotificationsCounter = styled.span`
  display: flex;
  position: relative;
  margin-right: ${LAYOUT.GUTTER / 2}px;
`;

export const StyledNotificationDotMarker = styled.span`
  ${({ theme }) => {
    const { color } = getFontStyles(theme.header.font, ['color']);
    return `
    background: ${color ?? 'inherit'};
    color: ${safePolished(readableColor)(color)};
    `;
  }}
  borderRadius: 50%;
  fontsize: 9px;
  height: 16px;
  padding: 2px;
  position: absolute;
  right: -5px;
  top: -5px;
  width: 16px;
`;
