import { useState, useEffect, useRef, type RefObject } from 'react';

export const useAnimatedVisibility = () => {
  const ref: RefObject<HTMLElement | undefined> = useRef();
  type Timeout = ReturnType<typeof setTimeout>;

  /*
   * in order to correctly handle animations and
   * visibility of the navigation drawer, we need to
   * use both open and visible state variables.
   *
   * `isOpen` controls the mounting of the drawer while
   * `isVisible` controls the visiblity of the drawer
   * to the user
   */

  const [isMounted, setMounted] = useState(false);
  const [shouldDisplay, setShouldDisplay] = useState(false);

  useEffect(() => {
    ref?.current?.addEventListener('transitionend', onTransitionEnd);

    return () => ref?.current?.removeEventListener('transitionend', onTransitionEnd);
  });

  useEffect(() => {
    let timeout: Timeout;

    if (isMounted) {
      timeout = setTimeout(() => setShouldDisplay(true), 10);
    }

    return () => clearTimeout(timeout);
  }, [isMounted]);

  const onTransitionEnd = () => !shouldDisplay && setMounted(false);

  const toggle = () => {
    if (isMounted) {
      setShouldDisplay(false);
    } else {
      setMounted(true);
    }
  };

  return { isMounted, shouldDisplay, toggle, ref };
};
