import { type SVGProps } from 'react';
import { type IconProps } from './types';

export const Menu = ({ title, ...otherProps }: IconProps & SVGProps<SVGSVGElement>) => (
  <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title>{title}</title>

    <path
      d="M23 5.778A.778.778 0 0 0 22.222 5H1.778a.778.778 0 1 0 0 1.556h20.444c.43 0 .778-.349.778-.778zM23 12a.778.778 0 0 0-.778-.778H1.778a.778.778 0 1 0 0 1.556h20.444c.43 0 .778-.348.778-.778zm-.778 5.444a.778.778 0 0 1 0 1.556H1.778a.778.778 0 1 1 0-1.556h20.444z"
      fill="currentColor"
    />
  </svg>
);
