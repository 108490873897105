export type Placement = 'leading' | 'center' | 'trailing';
export type Direction = 'horizontal' | 'vertical';

export const getPlacement = (value: Placement) => {
  switch (value) {
    case 'leading':
      return 'flex-start';

    case 'center':
      return 'center';

    case 'trailing':
      return 'flex-end';

    default:
      throw new Error('undefined alignment');
  }
};

export const getDirection = (value: Direction) => {
  switch (value) {
    case 'horizontal':
      return 'row';

    case 'vertical':
      return 'column';

    default:
      throw new Error('undefined direction');
  }
};
