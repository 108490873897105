import { type SVGProps } from 'react';
import { type IconProps } from './types';

export const Instagram = ({ title, ...otherProps }: IconProps & SVGProps<SVGSVGElement>) => (
  <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title>{title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7929 19.2H7.2067C5.88202 19.2 4.7998 18.1178 4.7998 16.7931V7.20695C4.7998 5.88227 5.88202 4.80005 7.2067 4.80005H16.7929C18.1176 4.80005 19.1998 5.88227 19.1998 7.20695V16.7931C19.1998 18.1237 18.1235 19.2 16.7929 19.2ZM12.0027 15.6991C11.0151 15.6991 10.0867 15.3147 9.38886 14.6169C8.69103 13.919 8.30664 12.9906 8.30664 12.003C8.30664 11.0154 8.69103 10.0869 9.38886 9.3891C10.0867 8.69128 11.0151 8.30688 12.0027 8.30688C12.9903 8.30688 13.9188 8.69128 14.6166 9.3891C15.3144 10.0869 15.6988 11.0154 15.6988 12.003C15.6988 12.9906 15.3144 13.919 14.6166 14.6169C13.9129 15.3147 12.9903 15.6991 12.0027 15.6991ZM12.0027 9.09341C10.4001 9.09341 9.09317 10.3944 9.09317 12.003C9.09317 13.6056 10.3942 14.9126 12.0027 14.9126C13.6054 14.9126 14.9123 13.6115 14.9123 12.003C14.9064 10.4004 13.6054 9.09341 12.0027 9.09341ZM17.1288 7.47578C17.1288 7.86772 16.8111 8.18545 16.4191 8.18545C16.0272 8.18545 15.7095 7.86772 15.7095 7.47578C15.7095 7.08384 16.0272 6.76611 16.4191 6.76611C16.8111 6.76611 17.1288 7.08384 17.1288 7.47578Z"
      fill="currentColor"
    />
  </svg>
);
