import { type SVGProps } from 'react';
import { type IconProps } from './types';

export const Heart = ({ title, ...otherProps }: IconProps & SVGProps<SVGSVGElement>) => (
  <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title>{title}</title>

    <path
      d="M12 20.25S2.625 15 2.625 8.625A4.876 4.876 0 0 1 12 6.747h0a4.876 4.876 0 0 1 9.375 1.878C21.375 15 12 20.25 12 20.25z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
