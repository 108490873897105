import { type ConnectedProps, connect } from 'react-redux';
import { type FEATURE_FLAGS, FLAG_RELATIONSHIP } from '@/constants/features';
import { type RootState } from '@/types/app';

interface Props extends ReduxProps {
  children: React.ReactNode;
  featureFlag: FEATURE_FLAGS | FEATURE_FLAGS[];
  renderFallback?: React.ReactNode;
  flagRelationship?: FLAG_RELATIONSHIP;
}

const _FeatureAccessor = ({
  children,
  featureFlag,
  featureBag,
  renderFallback,
  flagRelationship = FLAG_RELATIONSHIP.AND,
}: Props) => {
  let renderChildren = false;

  if (featureFlag instanceof Array) {
    renderChildren =
      flagRelationship === FLAG_RELATIONSHIP.OR
        ? featureFlag.some((flag) => featureBag[flag])
        : featureFlag.every((flag) => featureBag[flag]);
  } else {
    renderChildren = featureBag[featureFlag];
  }

  if (renderChildren) {
    return <>{children}</>;
  }

  if (renderFallback) {
    return <>{renderFallback}</>;
  }

  return null;
};

const mapStateToProps = (state: RootState) => ({
  featureBag: state.app.global.featureBag,
});
const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export const FeatureAccessor = connector(_FeatureAccessor);
