import styled from 'styled-components';
import { MARKETING_TAG_POSITION } from '@/constants/tags';

interface StyledMarketingTagProps {
  backgroundColor: string;
  textColor: string;
  position: string;
}

export const StyledMarketingTag = styled.span<StyledMarketingTagProps>`
  --marketing-tag_font-size: 11px;

  ${({ backgroundColor, textColor }) => `
    --marketing-tag_background: ${backgroundColor};
    --marketing-tag_color: ${textColor};
  `}

  display: flex;
  background: var(--marketing-tag_background);
  color: var(--marketing-tag_color);
  padding: var(--size-1) var(--size-2);
  font-size: var(--marketing-tag_font-size);
  align-items: center;
  justify-content: center;
  border-radius: var(--size-1);

  ${({ position }) =>
    position === MARKETING_TAG_POSITION.MENU_CARD
      ? `
    position: absolute;
    top: var(--size-2);
    left: 0;
    border-radius: 0px var(--size-1) var(--size-1) 0px;
  `
      : ''}
`;

interface StyledTagIconProps {
  position: string;
}

export const StyledTagIcon = styled.img<StyledTagIconProps>`
  max-height: ${({ position }) =>
    position === MARKETING_TAG_POSITION.MENU_CARD ? '20px' : '18px'};
  margin-right: var(--size-1);
`;
