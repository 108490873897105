import { readableColor } from 'polished';
import styled from 'styled-components';
import { LAYOUT, ORDER } from '@/constants/styles';
import { safePolished } from '@/utils/safePolished';

export const StyledLoyaltyNavContainer = styled.div`
  background: ${({ theme }) => theme.menu_categories.category_nav_background_color};
  border-bottom: solid 1px ${({ theme }) => theme.global.primary_border_color};
  height: 3.75rem;

  position: fixed;
  top: var(--size-12);
  width: 100%;
  z-index: ${ORDER.LOYALTY_HEADER};

  @media (min-width: 768px) {
    height: 5rem;
    top: var(--size-20);
  }
`;

export const StyledLoyaltyNav = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${LAYOUT.MAXWIDTH}px;
  width: 100%;

  @media (max-width: 767px) {
    align-items: center;
    display: flex;
    max-width: 100%;
    overflow: scroll;
  }
`;

interface StyledLoyaltyButtonProps {
  $active: boolean;
}

export const StyledLoyaltyButton = styled.a<StyledLoyaltyButtonProps>`
  align-items: center;
  background: none;
  border: none;
  border-top: 5px solid transparent;
  border-bottom: ${({ $active, theme }) =>
    `5px solid ${$active ? theme.global.primary_active_color : 'transparent'}`};
  color: ${({ theme }) => theme.menu_categories.category_nav_font.color};
  cursor: pointer;
  flex: 1;
  font-family: ${({ theme }) => theme.primary_font.bold.font_family};
  font-size: ${({ theme }) => theme.accounts.nav_font_size}px;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 ${LAYOUT.GUTTER / 2}px;

  @media (hover: hover) {
    &:hover {
      border-bottom: 5px solid ${({ theme }) => theme.global.primary_active_color};
    }
  }

  @media (max-width: 767px) {
    flex: 0 0 auto;
    font-size: 14px;
  }
`;

export const StyledNavItemAndNumberContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLoyaltyNavNumber = styled.span`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 3px;
  background: ${({ theme }) => theme.text.primary_font.color};
  color: ${({ theme }) => safePolished(readableColor)(theme.text.primary_font.color)};
  text-align: center;
  font-size: 12px;
  margin-left: ${LAYOUT.GUTTER / 2}px;
  object-fit: contain;

  @media (max-width: 767px) {
    min-width: 24px;
    min-height: 24px;
  }
`;
