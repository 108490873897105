import styled from 'styled-components';
import { getFontStyles } from '@/redux/cmsConfig/utils';

export const Container = styled.header(({ theme }) => {
  const subtitleProperties = getFontStyles(theme.android_drawer.subtitle_font);
  return {
    '--navigation-item__border-color': 'transparent',
    '--navigation-item__font-family': (subtitleProperties.fontFamily as string) ?? 'inherit',
    '--navigation-item__font-weight': 'normal',
    '--navigation-item__padding-x': 0,
    '--navigation-item__padding-y': 'var(--size-2)',
    '--navigation-item__height': 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: 'var(--size-48)',
    padding: 'var(--size-18) var(--size-4) var(--size-4)',
  };
});

export const Title = styled.h2(({ theme }) => {
  const titleProperties = getFontStyles(theme.android_drawer.title_font);
  return {
    '--android-navigation-title__color': titleProperties.color,
    '--android-navigation-title__font-family': (titleProperties.fontFamily as string) ?? '',
    '--android-navigation-title__font-size': (titleProperties.fontSize as string) ?? '',
    color: 'var(--android-navigation-title__color, --config-global__secondary-body-color)',
    fontFamily:
      'var(--android-navigation-title__font-family, --config-secondary-font-family__bold)',
    fontSize: 'var(--android-navigation-title__font-size, --config-text__secondary-text-size)',
    lineHeight: 'normal',
    margin: '0',
  };
});
