import { SVG } from './style';
import Animation from '@/components/uielements/animation';

interface Props {
  title?: string;
}

export const Loading = ({ title = 'Loading' }: Props) => (
  <Animation.Spin>
    <SVG width="24" height="24" viewBox="0 0 24 24" fill="none">
      <title>{title}</title>
      <path
        d="M21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12C2.5 6.75329 6.75329 2.5 12 2.5V0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12H21.5Z"
        fill="currentColor"
      />
    </SVG>
  </Animation.Spin>
);
