import { type ReactElement, type SVGProps } from 'react';
import { Back } from './back';
import { BackEnclosed } from './back-enclosed';
import { Caret } from './caret';
import { Cart } from './cart';
import { CheckMark } from './check-mark';
import { Close } from './close';
import { Ellipsis } from './ellipsis';
import { Heart } from './heart';
import { History } from './history';
import { Loading } from './loading';
import { Mail } from './mail';
import { Menu } from './menu';
import { Plus } from './plus';
import { Reward } from './reward';
import { Image } from './styles';

interface CustomProps {
  src?: string;
  fallback: ReactElement;
  title: string;
}

const Custom = ({ src, fallback, title, ...otherProps }: CustomProps & SVGProps<SVGSVGElement>) => {
  return src ? (
    <>
      {/* @ts-expect-error ref mismatch */}
      <Image src={src} alt={title} {...otherProps} />
    </>
  ) : (
    fallback
  );
};

const Icon = {
  Cart,
  CheckMark,
  Back,
  BackEnclosed,
  Caret,
  Close,
  Custom,
  Ellipsis,
  Heart,
  History,
  Loading,
  Mail,
  Menu,
  Reward,
  Plus,
};

export default Icon;
