import {
  type AllergenItem,
  type BasketItemOption,
  type MenuProduct,
  type MenuProductOption,
} from '@koala/sdk';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Stack } from '@/components/ui';
import { ALLERGEN_CARD_MODE } from '@/constants/global';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { type RootState } from '@/types/app';

interface Props {
  compileAllergensMethod?: (
    product: MenuProduct,
    selectedOptions?: BasketItemOption[],
    mode?: string,
  ) => AllergenItem[];
  mode: (typeof ALLERGEN_CARD_MODE)[keyof typeof ALLERGEN_CARD_MODE];
  productAllergens: MenuProduct | MenuProductOption;
  selectedOptions?: BasketItemOption[];
}

export function Allergens(props: Props) {
  const { compileAllergensMethod, mode, productAllergens, selectedOptions } = props;
  const { activeAllergens } = useSelector((state: RootState) => state.app.allergens);

  const [compiledAllergens, setCompiledAllergens] = useState<AllergenItem[]>([]);

  const displayedTags: number[] = [];

  useEffect(() => {
    if (compileAllergensMethod) {
      const compiledAllergens: AllergenItem[] = compileAllergensMethod(
        productAllergens as unknown as MenuProduct,
        selectedOptions || [],
        mode,
      );
      setCompiledAllergens(compiledAllergens);
    }
  }, [activeAllergens]);

  if (!activeAllergens?.length || !compiledAllergens?.length) {
    return null;
  }

  return (
    <Stack gap="$1">
      {compiledAllergens.map((allergen: AllergenItem) => {
        const id = Number(allergen.id);

        if (
          displayedTags.includes(id) ||
          !activeAllergens.includes(id) ||
          (allergen.is_removal && mode === ALLERGEN_CARD_MODE.OPTIONS)
        ) {
          return null;
        }

        displayedTags.push(id);

        return <DietaryTag key={allergen.id}>{allergen.label}</DietaryTag>;
      })}
    </Stack>
  );
}

const DietaryTag = styled.span(({ theme }) => ({
  backgroundColor: theme.dietary_preferences_tag.background_color ?? 'transparent',
  border: '1px solid',
  borderColor: theme.dietary_preferences_tag.border_color ?? 'transparent',
  borderRadius: theme.dietary_preferences_tag.border_radius ?? 0,
  fontWeight: 500,
  padding: '.25rem',
  ...getFontStyles(theme.dietary_preferences_tag.font, {
    line_height: () => '1',
  }),
}));
