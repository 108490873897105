import { Element } from './button.styles';

interface Props {
  children: React.ReactNode;
  overrides?: React.CSSProperties;
}

export const Button = ({
  children,
  overrides = {},
  ...otherProps
}: Props & React.HTMLProps<HTMLButtonElement>) => {
  return (
    // @ts-expect-error
    <Element $styles={overrides} {...otherProps}>
      {children}
    </Element>
  );
};
