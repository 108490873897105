import { type SVGProps } from 'react';
import { type IconProps } from './types';

export const CheckMark = ({ title, ...otherProps }: IconProps & SVGProps<SVGSVGElement>) => (
  <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title>{title}</title>

    <path
      d="M19.7071 7.29289C20.0676 7.65338 20.0953 8.22061 19.7903 8.6129L19.7071 8.70711L11.0404 17.3738C10.8902 17.524 10.7041 17.6164 10.5098 17.6511L10.3924 17.6649H10.2743C10.0777 17.6534 9.88389 17.5841 9.72043 17.457L9.62623 17.3738L5.29289 13.0404C4.90237 12.6499 4.90237 12.0168 5.29289 11.6262C5.65338 11.2657 6.22061 11.238 6.6129 11.543L6.70711 11.6262L10.333 15.252L18.2929 7.29289C18.6834 6.90237 19.3166 6.90237 19.7071 7.29289Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
