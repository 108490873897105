import { type WebConfig } from '@koala/sdk';
import styled from 'styled-components';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import { getFontStyles } from '@/redux/cmsConfig/utils';

interface ThemeProps {
  theme: WebConfig;
}

export const StyledReorderForm = styled.form`
  padding: var(--size-8) 0 0;

  @media (min-width: 768px) {
    padding-bottom: var(--size-5);
  }
`;

export const StyledReorderFormHeader = styled.div`
  padding: 0 var(--size-5);
  margin: 0 0 var(--size-8);
`;

export const StyledReorderFormTitle = styled.h3(({ theme }) => ({
  marginBottom: 'var(--size-1)',
  ...getFontStyles(theme.modals.secondary_font, ['font_size']),
}));

export const StyledReorderFormSubtitle = styled.p(({ theme }) => ({
  fontSize: '14px',
  margin: 0,
  /** @FIXME font refactor. this used to have a fallback to `theme.modals.font_color` at 30% but since we always have a value, that isn't going to work */
  ...getFontStyles(theme.modals.tertiary_font, ['color']),
}));

export const StyledReorderFormItemList = styled.ul`
  margin: 0;
  padding: 0;
  text-align: left;
`;

export const StyledReorderModalButtonWrapper = styled.div`
  ${(props: ThemeProps) => `
    --reorder-modal-button-wrapper__background-color: ${
      props.theme.modals.background_color ?? 'transparent'
    };
    --reorder-modal-button-wrapper__border-color: ${
      props.theme.modals.border_color ?? 'transparent'
    };
  `}

  background: var(--reorder-modal-button-wrapper__background-color);
  border-top: 1px solid var(--reorder-modal-button-wrapper__border-color);
  bottom: var(--neg-size-5);
  padding: var(--size-5) var(--size-5) var(--size-8);
  position: sticky;

  @media (min-width: 768px) {
    bottom: 0;
    padding: var(--size-5);
  }
`;

export const StyledReorderModalButton = styled(StyledPrimaryButton)`
  width: 100%;
`;

export const ReorderModalCancelButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: var(--config-text__primary-text-color);
  margin: var(--size-3) 0 0;
  width: 100%;
`;

export const StyledReorderModalListItem = styled.li(({ theme }) => ({
  borderBottom: `1px solid ${theme.global.primary_border_color}`,
  display: 'flex',
  listStyleType: 'none',
  padding: 'var(--size-5)',
  ...getFontStyles(theme.modals.font, ['font_size']),

  p: {
    fontSize: '14px',
    margin: 'var(--size-1) 0 0',
  },
}));

export const StyledReorderModalQuantity = styled.div`
  padding: 0 var(--size-4);
`;

export const StyledReorderModalModifiers = styled.div(({ theme }) => ({
  fontSize: '14px',
  margin: 'var(--size-1) 0 0',
  /** @FIXME font refactor. this used to have a fallback to `theme.modals.font_color` at 30% but since we always have a value, that isn't going to work */
  ...getFontStyles(theme.modals.tertiary_font, ['color']),
}));

export const ReorderModalConfirmDescription = styled.p`
  padding: 0 var(--size-5);
`;
