import {
  StyledNestedOptionContainer,
  StyledOptionGroupHeader,
  StyledTopOptionContainer,
} from '../styles';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { StyledInput } from '@/components/uielements/form-fields/styles';
import { CSS_CLASSES } from '@/constants/cssClassNames';
import { useSelector } from '@/redux';
import { safelyGetString } from '@/utils/stringHelpers';

interface Props {
  value: string;
  onChange: (value: string) => void;
  maxLength: number;
}

export function SpecialInstructions({ value, onChange, maxLength }: Props) {
  const { strings } = useSelector(({ app }) => app.cmsConfig);

  return (
    <StyledTopOptionContainer>
      <StyledNestedOptionContainer>
        <StyledOptionGroupHeader className={CSS_CLASSES.PRODUCT_DETAIL.SPECIAL_INSTRUCTION_HEADER}>
          <StringAccessor
            tag="h3"
            accessor="product_details.special_instructions_header"
            html={false}
          />
          <StringAccessor
            tag="p"
            accessor="product_details.special_instructions_subheader"
            html={false}
          />
        </StyledOptionGroupHeader>
        <StyledInput
          placeholder={safelyGetString(strings, 'product_details.special_instructions_placeholder')}
          maxLength={maxLength}
          onChange={(e) => {
            // Ensure we do not exceed the max byte length
            if (maxLength && e.target.value.length > maxLength) {
              return;
            }
            onChange(e.target.value);
          }}
          value={value}
        />
      </StyledNestedOptionContainer>
    </StyledTopOptionContainer>
  );
}
